.bannerBoxShadow {
  position: relative;
}
.bannerBoxShadow::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  background: linear-gradient(135deg, #4d8bfb 0%, #242846 100%);
  filter: blur(150px);
  z-index: -1;
  opacity: 0.34;
  transform: translate(-50%, -50%);
}
#tv-attr-logo {
  display: none !important;
}
.HlogoSignup {
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 0px;
}
.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #ffffff99; /* Black dot */
  border-radius: 50%;
}
@keyframes pulseAnimation {
  0% {
    transform: scale(0.9);
  }
  // 25% {
  //   transform: scale(1);
  // } /* Scale up */
  50% {
    transform: scale(1);
  } /* Scale up */
  // 75% {
  //   transform: scale(1);
  // } /* Scale up */
  100% {
    transform: scale(0.9);
  }
}
@keyframes floatAnimation {
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); } /* Move up */
  100% { transform: translateY(0); }
}
.animated-image {
  animation: pulseAnimation 1.5s infinite ease-in-out;
  cursor: pointer;
}
.animated-image:hover {
  animation-play-state: paused; /* 🔥 Stops animation on hover */
}
@keyframes shakeAnimation {
  0%, 100% { transform: translateY(0); }
  25% { transform: translateY(-5px); }
  50% { transform: translateY(5px); }
  75% { transform: translateY(-5px); }
}

.shake-animated-image {
  animation: shakeAnimation 2s ease-in-out 2; /* 🔥 Runs for 2 seconds & stops */
}
.slicktopslider .slick-prev {
  left: 0 !important;
  top: -14px !important;
  right: 60px !important;
  color: #fff !important;
  z-index: 999 !important;
  background-color: transparent !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  top: 50% !important;
}
.slicktopslider .slick-next {
  left: auto !important;
  top: -14px !important;
  right: 10px !important;
  color: #fff !important;
  z-index: 999 !important;
  background-color: transparent !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  top: 50% !important;
}
.text-3d {
  font-size: 18px;
  color: #ffd155;
  background: transparent;
  display: inline-block;
  text-align: center;
}
.lastSpinDate {
  position: absolute;
  left: 0%;
  top: -3.5%;
  // cursor: pointer;
  @media (max-height: 650px) {
    top: -6%;
  }
}
.free-spin-countdown {
  @media (max-height: 650px) {
    font-size: 14px !important;
  }
}
.slicktopslider .slick-next:before,
.slicktopslider .slick-prev:before {
  font-size: 67px;
  line-height: 1;
  opacity: 0.75;
  color: #ffffff;
  margin-top: -17px;
}

.footballslider {
  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 40px;
    height: 40px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    border-radius: 50%;
    background: 0 0;
  }
  .slick-prev:before {
    content: "‹";
  }
  .slick-next:before {
    content: "›";
  }
  .slick-prev {
    left: -41px;
    top: -20px !important;
    top: -163px !important;
    right: 60px !important;
    top: 50% !important;
    color: #fff !important;
    z-index: 999 !important;
    background-color: #edae50 !important;
    border: 2px solid #ffca64;

    &:hover {
      background-color: #edae50 !important;
      border: 2px solid #ffca64;
    }
    &.slick-disabled {
      opacity: 0.5;
    }
  }
  .slick-next {
    top: 50% !important;
    bottom: -20px !important;
    left: auto !important;
    right: -41px;
    color: #fff !important;
    background-color: #edae50 !important;
    border: 2px solid #ffca64;
    z-index: 999 !important;
    &:hover {
      background-color: #edae50 !important;
      border: 2px solid #ffca64;
    }
    &.slick-disabled {
      opacity: 0.5;
    }
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 1 !important;
    color: #fff;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    margin-top: -17px;
  }
}
form {
  background: rgba(41, 45, 79, 1);
  display: flex;
  // align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;
  height: 100%;
  text-align: left;
  // max-height: 520px;
  overflow: auto;
  overflow-x: hidden;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.container {
  // background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  // overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 580px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.deposit-pay {
  left: 0;
  width: 50%;
  z-index: 2;
}

.deposit-right {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}
.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.auth-modal.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.auth-modal.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.auth-modal.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: rgba(53, 58, 102, 1);

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.auth-modal.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.auth-modal.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.auth-modal.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}
.noPadding .MuiDialogContent-root {
  padding: 0 !important;
  padding-top: 0 !important;
}
.textLeft {
  text-align: left !important;
}
.borderRight {
  border-right: 2px solid #2b3054;
}
.noHeight {
  height: auto;
  max-height: unset;
  border-radius: 10px;
  background: #1c1f36;
}
.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  font-size: 25px;
  cursor: pointer;
}
.googleButton > * {
  text-align: center;
  width: 100%;

  display: inline-block !important;
}
.profile_picture {
  width: 200px !important;
  height: 200px !important;
}
