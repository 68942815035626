@media (max-width: 1440px) {
}
@media (max-width: 1462px) {
  .royalText {
    font-size: 23px !important;
    line-height: 37px !important;
  }
  .selectGridBox {
    width: 19%;
  }
  .buttonCustom {
    // width: 87px !important;
    // height: 87px !important;
  }
  .horsetopBox {
    margin-top: 93px;
  }
}
@media (max-width: 1280px) {
  .influcenbox .textBox {
    min-height: 114px !important;
  }
  .bannerimg1 {
    width: auto;
    max-width: 250px;
  }
  .royalText {
    font-size: 23px !important;
    line-height: 37px !important;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .selectGridBox {
    width: 20%;
  }
  .buttonCustom {
    // width: 87px !important;
    // height: 87px !important;
  }
}
@media (max-width: 1110px) {
  .selectGridBox {
    width: 30%;
  }
}
@media (max-width: 1024px) {
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 70px;
  }
  .selectGridBox {
    width: 30%;
    margin: 5px;
  }
}
@media (max-width: 1440px) {
  .albumslickbottomslider .slick-next {
    left: 51% !important;
  }
}

@media (max-width: 1280px) {
  .albumslickbottomslider .slick-next {
    left: 52% !important;
  }
}
@media (max-width: 991px) {
  .balckjacklogo {
    display: none;
  }
  .socialresponBox {
    justify-content: space-around;
  }
  .logoDrawerBox {
    // padding-top: 12px;
    /* top: 20px; */
    width: auto;
    cursor: pointer;
    max-width: 75px;
  }
  .sideMenuBox {
    height: calc(100vh - 355px);
    overflow: auto;
  }
  .bannerimg1 {
    width: auto;
    max-width: 200px;
  }
  .albumslickbottomslider .slick-next {
    left: 55% !important;
  }
  .selectGridBox {
    width: 18%;
    margin: 5px;
  }
  .slick-next {
    left: 52% !important;
  }
  .circleBox {
    width: 500px;
    height: 500px;
  }
  .circleBoxNew {
    width: 500px;
    height: 500px;
  }

  .squarecontentBox {
    width: 40px !important;
  }
  .containerBox {
    padding: 10px !important;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  // .orangeshadeBox {
  //   display: none;
  // }
  .purpleBox {
    display: none;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 70px;
  }
}

@media (max-width: 767px) {
  .prizetext {
    min-width: 92px;
  }
  form {
    padding: 15px !important;
  }
  .lotteryquantityBox {
    .MuiFormControl-root {
      width: 25px;
    }
    .MuiOutlinedInput-input {
      padding: 10.5px 12px;
      text-align: center;
    }
  }
  .quantitytextmain {
    right: -2px !important;
  }
  .tickenumber {
    padding: 9px !important;
    width: 27px !important;
  }
  .bottomImage {
    display: none;
  }
  .numberBox {
    width: 30px;
    height: 30px;
  }
  .timelineBoxmain {
    .MuiTimelineDot-root {
      margin-top: -4px;
      box-shadow: none;
    }
    .MuiTimelineSeparator-root {
      margin-right: 11px;
    }
    .MuiTimelineItem-alignAlternate:nth-child(even) {
      flex-direction: initial;
    }
    .MuiTimelineConnector-root {
      display: none;
    }
    .MuiTimelineOppositeContent-root {
      display: none;
    }
    .MuiTimeline-root {
      padding: 0px;
    }
    .MuiTimelineSeparator-root {
      // display: none;
    }
    .MuiTimelineItem-alignAlternate:nth-child(even) .MuiTimelineItem-content {
      text-align: left;
    }
  }
  .featuresMainBox {
    padding: 30px 0;
  }
  .featuresGrid {
    width: 70%;
    height: 200px;
  }
  .logoClientBox {
    width: auto;
    max-width: 55px;
    margin: 0 20px;
  }
  .wrapBox {
    flex-wrap: wrap;
  }
  .referalTextBox {
    text-align: left;
  }
  .mainreferral {
    margin-top: 10px;
  }
  .overlay-container {
    display: none;
  }
  .deposit-right {
    display: none;
  }
  .deposit-pay {
    width: 100% !important;
  }
  .container {
    width: 380px !important;
    overflow-x: hidden !important;
  }
  .sign-up-container {
    width: 100% !important;
  }
  .sign-in-container {
    width: 100% !important;
  }
  .container .sign-up-container {
    transform: translateX(0%) !important;
  }
  .firstNumber {
    width: 42px !important;
  }
  .footballslider .slick-next {
    right: -11px !important;
  }
  .footballslider .slick-prev {
    left: -11px !important;
  }
  .bannerimg1 {
    width: auto;
    max-width: 100px;
  }
  .numberText {
    left: -35px !important;
    top: 79px;
    width: 52px;
  }
  .albumslickbottomslider .slick-prev {
    left: 45% !important;
  }
  .buttonCustom {
    // width: 50px !important;
    // height: 50px !important;
    padding: 12px 0px;
  }
  .selectGridBox {
    width: 28%;
    margin: 5px;
  }

  .slick-next {
    left: 52% !important;
  }
  .hedaerTopbar {
    padding: 5px 0;
  }
  .ticketbannerImg {
    top: -28px;
  }
  .dollerImg3 {
    left: 38%;
    width: 84px !important;
    bottom: -16px;
  }

  .dollerImg2 {
    // left: 0;
    // width: 117px !important;
    // top: 41%;
    position: absolute;
    left: 45%;
    width: 117px !important;
    top: -7%;
    transform: translateX(-50%);
  }
  .dollerImg1 {
    width: 86px !important;
    top: 36%;
  }
  .TopbarButton {
    padding: 8px 24px;
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 52px;
  }
  .sliderBox img {
    width: auto;
    max-width: 135px;
  }
  .imgNoData img {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .footballslider .slick-next,
  .footballslider .slick-prev {
    width: 28px !important;
    height: 28px !important;
  }
  .footballslider .slick-next {
    margin-right: 4px;
  }
  .footballslider .slick-prev {
    margin-left: 4px;
  }
  .footballslider .slick-next:before,
  .footballslider .slick-prev:before {
    font-size: 34px !important;
    margin-top: -14px !important;
  }
}
