@charset "UTF-8";
body {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  background: #141627;
  color: #ffffff;
  font-family: "Readex Pro", sans-serif !important;
}

.dFlexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justifyBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textFieldSection {
  width: 100%;
}

.width100 {
  width: 100%;
}

.royalrightImg {
  width: auto !important;
  max-width: 97px;
  margin-top: 34px;
  margin-right: -3px;
}

.numberText1 {
  left: -70px !important;
}

.numberText {
  position: absolute;
  left: -46px;
  font-size: 101px !important;
  color: #ca772a;
  top: 54px;
  z-index: 9;
}

.layoubackgroundImg {
  position: fixed;
  width: auto;
  max-width: 100%;
  opacity: 0.5;
}

.sideMenuBox {
  overflow: auto;
}

.sideMenuBox1 {
  height: 100%;
  overflow: auto;
}

.buttonLeaf1 {
  font-size: 12px;
}
.buttonLeaf1 span {
  color: rgba(255, 255, 255, 0.7);
}

.sideMenuBox::-webkit-scrollbar {
  display: none;
}

.logoDrawerBox {
  padding-top: 20px;
  /* top: 20px; */
  width: auto;
  cursor: pointer;
  max-width: 119px;
  padding-bottom: 22px;
}

.paperBox {
  background-color: rgba(255, 255, 255, 0.025);
  border-radius: 15px;
  padding: 20px;
}

.slickbottomslider {
  width: 100%;
  position: relative;
}
.slickbottomslider .slick-initialized .slick-slide.slick-current .active {
  transform: scale(1.15);
  transition: all 0.4s ease-in;
}
.slickbottomslider .slick-next:before,
.slickbottomslider .slick-prev:before {
  font-size: 40px;
  line-height: 1;
  opacity: 1 !important;
  color: #fff;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  margin-top: -17px !important;
}
.slickbottomslider .slick-track {
  padding: 60px 0;
}
.slickbottomslider .slick-prev:before {
  content: "‹";
}
.slickbottomslider .slick-next:before {
  content: "›";
}
.slickbottomslider .slick-list {
  padding-left: 0px !important;
}
.slickbottomslider .slick-prev {
  left: auto !important;
  top: -14px !important;
  right: 60px !important;
  color: #fff !important;
  z-index: 999 !important;
  background-color: #1c1c1b !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
@media (max-width: 767px) {
  .slickbottomslider .slick-prev {
    top: 21px !important;
  }
}
.slickbottomslider .slick-next {
  top: -14px !important;
  left: auto !important;
  right: 5px !important;
  color: #fff !important;
  background-color: #161616 !important;
  z-index: 999 !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
@media (max-width: 767px) {
  .slickbottomslider .slick-next {
    top: 21px !important;
  }
}
.slickbottomslider .slick-next:before,
.slickbottomslider .slick-prev:before {
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  color: #f5c843;
  margin-top: -17px;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 18px !important;
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: rgb(255, 212, 129) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 1px solid rgb(255, 212, 129);
  border-radius: 50px;
  width: 21px !important;
  height: 21px !important;
  line-height: 23px !important;
}

.slick-dots {
  bottom: -42px !important;
  z-index: 999;
}
@media (max-width: 1556px) {
  .slick-dots {
    bottom: -42px !important;
  }
}

.slickbottomslider .slick-track {
  padding: 40px 0 23px;
}

.yellowShade {
  position: absolute;
  width: 1000px;
  height: 829px;
  left: 30%;
  top: 50%;
  transform: translateX(-50%);
  background: radial-gradient(29.3% 29.3% at 55.72% 49.12%, #ffca64 0%, rgba(245, 200, 67, 0) 100%);
  opacity: 0.45;
  filter: blur(190px);
  max-width: 100%;
}

.tableShadow {
  position: absolute;
  width: 1000px;
  height: 829px;
  left: 50%;
  top: -13%;
  transform: translateX(-50%);
  background: radial-gradient(29.3% 29.3% at 55.72% 49.12%, #ffca64 0%, rgba(245, 200, 67, 0) 100%);
  opacity: 0.45;
  filter: blur(301px);
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #292e52;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(92.79deg, #ffb000 -32.2%, #ffb000 5.51%, #ca772a 54.01%, #ca772a 110.93%);
}

::-webkit-scrollbar-track {
  background: #141627;
  border-radius: 5px;
  border: 1px solid #141627;
}

::-webkit-scrollbar-corner {
  background: #141627;
}

body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.royalText {
  font-size: 35px !important;
  font-weight: 600;
  font-family: "K2D", sans-serif !important;
  line-height: 45px !important;
  text-align: left;
  text-transform: uppercase;
}

.displayWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.displayColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.displayStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.displayEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectGridBox {
  width: 20%;
}

.logoImg {
  width: 70px;
}

.centerLogoBox {
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 500px) {
  .centerLogoBox {
    display: none;
  }
}

.bgx1 {
  animation: rotating 26s linear infinite;
}

.bgx2 {
  animation: rotating2 6s linear infinite;
}

.crypto {
  animation: rotating3 3s linear infinite;
}
.goldshadow {
  width: 800px;
  top: 25%;
  opacity: 0.3;
  filter: blur(130px);
  height: 400px;
  border-radius: 1000px;
  left: 23%;
  z-index: -1;
  position: absolute;
  display: none;
  background-image: radial-gradient(22.67% 9.68% at 67.26% 8.27%, #ffca64 86%, #ffca64 41%);
}
@media (max-width: 991px) {
  .goldshadow {
    width: 216px;
    height: 216px;
  }
}
@media (max-width: 401px) {
  .goldshadow {
    width: 106px;
    height: 106px;
  }
}

@keyframes rotating3 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating2 {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.sliderBox {
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background-color: #121212;
  border-top: 3px solid #e35f24;
  text-align: center;
}
.sliderBox img {
  width: auto;
  max-width: 165px;
}

.shadeBox {
  right: auto;
  left: 0;
  width: 450px;
  /* bottom: auto; */
  filter: blur(230px);
  height: 550px;
  opacity: 0.55;
  top: 0;
  z-index: -1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, #ff6600 0%, rgba(255, 102, 0, 0.3098039216) 95.78%);
}

.orangeshadeBox {
  right: 0;
  left: 34%;
  width: 400px;
  /* bottom: auto; */
  filter: blur(147px);
  height: 400px;
  opacity: 0.55;
  top: 42%;
  z-index: -1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background: linear-gradient(135deg, #4d8bfb 0%, #242846 100%);
}

.TopbarButton {
  padding: 8px 24px;
}

.centerImg {
  position: absolute !important;
  left: 0 !important;
  /* background: gray; */
  width: 38px;
  margin-right: 41px;
}

.centerbannerimg {
  position: absolute;
  top: 80%;
  left: 70%;
  transform: translate(-50%, -50%);
  width: 100% !important;
  z-index: 1;
  opacity: 0.5;
  min-width: 800px;
  max-width: 60%;
}

.orangeshadeBoxWon {
  right: 0;
  left: 0;
  width: 300px;
  /* bottom: auto; */
  filter: blur(70px);
  height: 300px;
  /* opacity: 0.55; */
  top: 0;
  z-index: 1;
  position: absolute;
  transform: rotate(45deg);
  /* border-radius: 1000px; */
  background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgba(247, 164, 5, 0) 0%, rgba(248, 136, 6, 0.19) 95.78%);
}

.dollerImg1 {
  position: absolute;
  right: 40px;
  width: auto !important;
  max-width: 150px !important;
  top: 42%;
}

.dollerImg2 {
  position: absolute;
  left: 45%;
  width: auto !important;
  max-width: 150px !important;
  top: -13%;
  transform: translateX(-50%);
}

.dollerImg3 {
  position: absolute;
  left: 45%;
  width: auto !important;
  max-width: 119px !important;
  bottom: -29px;
}

.ticketbannerImg {
  position: absolute;
  top: -122px;
  z-index: -1;
  width: auto !important;
  max-width: 100% !important;
  display: none;
}

.ticketAnimationBox {
  animation: 3s ease-in-out 0s infinite normal none running rotating4;
}

@keyframes rotating4 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}

.animationText {
  color: #fff;
  animation: 2s anim-lineUp ease-out infinite;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0.5;
    transform: translateY(80%);
  }
  20% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.3;
    transform: translateY(0%);
  }
  50% {
    opacity: 0.2;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.hedaerTopbar {
  padding: 7px 0 0;
}

.containerBox {
  padding: 0px 80px !important;
}

.mainTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #5e4a2e;
  border-radius: 50px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px;
}

.tabActiveButtons {
  background: linear-gradient(164deg, #ffca64 0%, #ca772a 100%);
  border-radius: 50px;
  padding: 11px 35px;
  color: #fff !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  transition: all 500ms ease-in-out;
  font-family: "Readex Pro";
}
@media (max-width: 768px) {
  .tabActiveButtons {
    padding: 11px 23px;
  }
}

.tabButtons {
  border-radius: 50px;
  padding: 11px 35px;
  white-space: pre;
  cursor: pointer;
  color: rgb(184, 184, 184);
  font-family: "Readex Pro";
}
@media (max-width: 768px) {
  .tabButtons {
    padding: 11px 23px;
  }
}

.mainTab1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
}

.mainTab1::-webkit-scrollbar {
  display: none;
}

.outerScrollTab {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.0666666667);
  border-radius: 50px;
  padding: 3px 20px;
}

.tabActiveButtons1 {
  background: linear-gradient(164deg, #ffca64 0%, #ca772a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 11px 20px;
  color: #e49f46 !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  font-family: "Readex Pro";
}
@media (max-width: 768px) {
  .tabActiveButtons1 {
    padding: 8px 15px;
  }
}

.tabButtons1 {
  border-radius: 50px;
  padding: 11px 20px;
  white-space: pre;
  cursor: pointer;
  color: rgb(184, 184, 184);
  font-family: "Readex Pro";
}
@media (max-width: 768px) {
  .tabButtons1 {
    padding: 8px 15px;
  }
}

.MuiPaginationItem-outlined {
  border: 1px solid #181719 !important;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: #d2883f !important;
  border: 1px solid #f5bd64 !important;
  color: #fff !important;
  border-radius: 10px;
}

.MuiPaginationItem-root {
  height: 40px;
  border-radius: 10px;
  min-width: 40px;
}

.tabDisplayBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.textTabBox {
  padding: 5px 15px;
  cursor: pointer;
  color: #ffca64;
  border-bottom: 2px solid #ffca64;
}

.textTabActiveBox {
  padding: 5px 15px;
  color: rgb(184, 184, 184);
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.dialogStyle .MuiDialog-paperFullWidth {
  background: rgb(10, 8, 7);
  box-shadow: 0 1px 0 0 #ffca64, 0 -1px 0 0 #f4a91b, 1px 0 0 0 #ca772a, -1px 0 0 0 #ca772a, 1px -1px 0 0 #f4a91b, -1px 1px 0 0 #ca772a, 1px 1px 0 0 #f4a91b, -1px -1px 0 0 #ca772a;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.backButton {
  background: linear-gradient(105.04deg, #ffca64 -4.56%, #ca772a 93.4%);
  padding: 8px !important;
  margin-right: 8px !important;
}

.tooltipBox {
  font-size: 200px;
}

.displayAlign {
  display: flex;
  align-items: center;
}

.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 99%;
}

.leaderboardButton {
  background: var(--linear, linear-gradient(164deg, #ffca64 0%, #ca772a 100%));
  border-radius: 0px 0px 30px 30px;
  min-width: 105px;
  width: 245px;
  min-height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lederboardCenter {
  display: flex;
  justify-content: center;
  margin-top: -42px;
}

.closeButton {
  position: absolute !important;
  right: 11px !important;
  top: 2px !important;
}

.tickenumber {
  color: #fff;
  padding: 16px 12px;
  width: 43px !important;
  border: 1px solid #3f467d;
  position: relative;
  background: #222545;
  border-radius: 10px !important;
}
@media (max-width: 600px) {
  .tickenumber {
    padding: 13px 4px;
  }
}

.zoomed {
  transform: scale(1.3);
  /* Increase size by 20% */
}

.myCustomClass1::after {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.myCustomClass1:nth-bild(odd)::after {
  background: linear-gradient(90deg, #135a38 0%, rgba(13, 135, 204, 0.97) 43.68%, rgba(0, 0, 0, 0) 114.45%);
}

.myCustomClass::after {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.myCustomClass:nth-child(odd)::after {
  background: linear-gradient(90deg, #81155c 0%, rgba(57, 70, 187, 0.91) 42.53%, rgba(0, 0, 0, 0) 107.41%);
}

.trade-full {
  height: 300px;
}
@media (max-width: 600px) {
  .trade-full {
    height: 250px;
  }
}

.mainClx {
  color: rgba(255, 255, 255, 0.6);
}

.mainClx span {
  color: rgba(255, 255, 255, 0.6) !important;
}

.contactusText {
  cursor: pointer;
  font-size: 16px !important;
  text-decoration: underline;
  color: orange;
}

.socialButton {
  padding: 10px !important;
}

.royalText1 {
  position: absolute;
  right: -1px;
  transform: rotate(28deg);
  top: 16px;
  font-weight: bold;
  font-size: 12px;
  background: #ca772a;
  padding: 4px 0x;
  padding: 6px;
  /* border-radius: 10px; */
  /* margin: 10px; */
  z-index: 9;
}

.marquebackground {
  padding: 68px 0 40px;
}
@media (max-width: 767px) {
  .marquebackground {
    padding: 20px 0 40px;
  }
}

.marqueTextdescription {
  margin: 9px !important;
  font-size: 14px !important;
  background: #111111;
  padding: 17px 28px;
  border-radius: 20px;
  border: 1px solid rgba(237, 174, 80, 0.1803921569);
  border-radius: 100px;
  border: 1px solid #2f3458;
  color: rgba(255, 255, 255, 0.8) !important;
  background: #1d1f32;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25) inset;
}

.slickbottomsliderNew .slick-dots li button {
  font-size: 0 !important;
  line-height: 0 !important;
  display: block !important;
  width: 20px !important;
  height: 20px !important;
  padding: 5px !important;
  cursor: pointer !important;
  color: transparent !important;
  border: 0 !important;
  outline: 0 !important;
  background: 0 0 !important;
}
.slickbottomsliderNew .slick-dots li button {
  font-size: 0 !important;
  line-height: 0 !important;
  display: block !important;
  width: 20px !important;
  height: 20px !important;
  padding: 5px !important;
  cursor: pointer !important;
  color: transparent !important;
  border: 0 !important;
  outline: 0 !important;
  background: 0 0 !important;
}
.slickbottomsliderNew .slick-dots li button:before {
  font-size: 6px !important;
  line-height: 20px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 26px !important;
  height: 3px !important;
  border: none !important;
  content: "•" !important;
  text-align: center;
  opacity: 0.25;
  background-color: #f7941d !important;
  color: transparent !important;
}
.slickbottomsliderNew .slick-dots li button:before,
.slickbottomsliderNew .slick-next:before,
.slickbottomsliderNew .slick-prev:before {
  font-family: slick;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.profileButton {
  border: 1px solid #fff !important;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.25) !important;
  background: transparent !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #242846 !important;
  border-radius: 3px 0 0 0;
}

.react-tel-input .form-control {
  width: 100%;
  height: 42px;
  border-radius: 36px;
  background: #242846 !important;
  border: 1px solid #3f467d;
  color: #fff;
}

.react-tel-input .flag-dropdown {
  border-radius: 20px 0px 0px 20px !important;
  border: 1px solid #3f467d !important;
  background: #242846 !important;
}

.react-tel-input .selected-flag {
  border-radius: 20px 0px 0px 20px !important;
  background: #242846 !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 20px 0px 0px 20px !important;
  background: #242846 !important;
}

.lotteryquantityBox .MuiOutlinedInput-root {
  border: 1px solid #3f467d;
  position: relative;
  background: #222545;
  border-radius: 10px !important;
}
.lotteryquantityBox .MuiOutlinedInput-input {
  padding: 15.5px 1px;
  text-align: center;
}
.lotteryquantityBox .MuiFormControl-root {
  width: 45px;
}

.prizetext {
  min-width: 141px;
  text-transform: uppercase;
}

.MuiSelect-select:focus {
  border-radius: 100px !important;
}

.react-tel-input .country-list {
  background: #292d4f;
}

.react-tel-input .country-list .country.highlight {
  background: #424879;
}

.react-tel-input .country-list .country:hover {
  background: #424879;
}

.MuiAutocomplete-inputRoot {
  padding: 4px 9px !important;
}

.MuiPickersBasePicker-pickerView {
  padding: 10px !important;
}

.logoClientBox {
  width: auto;
  max-width: 100px;
  margin: 0 20px;
}

.featuresBox {
  width: 100%;
  display: -webkit-box;
  position: relative;
  overflow-x: scroll;
  margin-bottom: 50px;
}
.featuresBox p {
  margin-top: 10px;
}
.featuresBox h5 {
  color: #fff;
}

.featuresMainBox {
  padding: 50px 0 60px;
}

.featuresCradBox {
  background: #111111;
  border-radius: 20px;
  border: 1px solid #2f3458;
  color: rgba(255, 255, 255, 0.8) !important;
  background: #1d1f32;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25) inset;
  overflow: hidden;
  height: 100%;
  min-height: 229px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: start;
}
.featuresCradBox p {
  margin-top: 10px;
}
.featuresCradBox h5 {
  color: #fff;
}

.scrollBox {
  overflow-y: auto;
  max-height: 200px;
}

.MuiMenu-paper {
  max-height: calc(100% - 96px);
  background-color: #222545 !important;
  -webkit-overflow-scrolling: touch;
  border: 1px solid rgba(63, 70, 125, 0.2705882353);
}

.MuiTimelineConnector-root {
  width: 2px;
  height: 108px;
  flex-grow: 1;
  background-color: rgba(43, 48, 84, 0.5) !important;
}

.numberBox {
  width: 40px;
  height: 40px;
}

.MuiTimelineDot-defaultGrey {
  color: #fafafa;
  border-color: transparent !important;
  background-color: transparent !important;
}

.MuiTimelineDot-defaultPrimary {
  color: #fafafa;
  border-color: transparent !important;
  background-color: transparent !important;
}

.graphMenuClx .apexcharts-menu.apexcharts-menu-open {
  background: #1c1f36 !important;
  border: 1px solid rgba(244, 169, 27, 0.3294117647) !important;
  border-radius: 10px !important;
}
.graphMenuClx .apexcharts-theme-light .apexcharts-menu-item:hover {
  background: rgba(238, 238, 238, 0.3607843137) !important;
  border-radius: 5px !important;
}
.graphMenuClx .apexcharts-menu-item.exportSVG {
  color: rgba(255, 255, 255, 0.9019607843) !important;
}

.custom-facebook-button-class {
  /* Add your custom styles here */
  background-color: #3b5998;
  /* Facebook blue color */
  color: white;
  /* Add more styles as needed */
}

.MuiMenuItem-root {
  font-size: 12px;
}

.logotoside {
  margin-top: 52px;
}

.logobottomside {
  margin-top: 72px;
}

.horsetopBox {
  margin-top: 98px;
}

.horseBox {
  margin-top: 0px;
}

.jointTextBox {
  margin-top: 26px;
}

.shadeblue {
  position: absolute;
  inset: auto 10% 10% auto;
  transform: rotate(45deg);
  z-index: -1;
  width: 300px;
  height: 200px;
  border-radius: 1000px;
  background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(42, 123, 135) 0%, rgb(39, 121, 124) 95.78%);
  opacity: 0.55;
  filter: blur(100px);
}

.MuiTableCell-root {
  font-size: 11px !important;
}

.orDivider {
  position: relative;
}
.orDivider p {
  position: relative;
  padding: 2px 10px;
  background: #292d4f;
  display: inline-block;
}
.orDivider::before {
  content: "";
  height: 1px;
  background-color: #444b84;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
}

.responsibilitiesBox {
  padding: 40px 0 40px;
}

.gameplayCard {
  padding: 5px 18px;
  font-size: 16px !important;
  background: #252947;
  font-weight: 500 !important;
  line-height: 33px;
  border-radius: 20px 20px 0px 0px;
  text-transform: uppercase;
}

.remaiderBox {
  padding: 15px;
}

.google-login-button {
  font-size: 16px; /* Adjust the font size as needed */
}

.MuiSvgIcon-fontSizeSmall {
  color: #fff !important;
}

.blink-soft {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.coubtrymainBox .MuiFormControl-fullWidth {
  width: 100%;
  border: 1px solid  #3f467d !important;
  border-radius: 100px;
}

.blink-soft {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
blinking-text-container {
  position: relative;
}

.blinking-text {
  background-color: orange;
  border-radius: 10px;
  padding: 10px;
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 1462px) {
  .royalText {
    font-size: 23px !important;
    line-height: 37px !important;
  }
  .selectGridBox {
    width: 19%;
  }
  .horsetopBox {
    margin-top: 93px;
  }
}
@media (max-width: 1280px) {
  .influcenbox .textBox {
    min-height: 114px !important;
  }
  .bannerimg1 {
    width: auto;
    max-width: 250px;
  }
  .royalText {
    font-size: 23px !important;
    line-height: 37px !important;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .selectGridBox {
    width: 20%;
  }
}
@media (max-width: 1110px) {
  .selectGridBox {
    width: 30%;
  }
}
@media (max-width: 1024px) {
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 70px;
  }
  .selectGridBox {
    width: 30%;
    margin: 5px;
  }
}
@media (max-width: 1440px) {
  .albumslickbottomslider .slick-next {
    left: 51% !important;
  }
}
@media (max-width: 1280px) {
  .albumslickbottomslider .slick-next {
    left: 52% !important;
  }
}
@media (max-width: 991px) {
  .balckjacklogo {
    display: none;
  }
  .socialresponBox {
    justify-content: space-around;
  }
  .logoDrawerBox {
    /* top: 20px; */
    width: auto;
    cursor: pointer;
    max-width: 75px;
  }
  .sideMenuBox {
    height: calc(100vh - 355px);
    overflow: auto;
  }
  .bannerimg1 {
    width: auto;
    max-width: 200px;
  }
  .albumslickbottomslider .slick-next {
    left: 55% !important;
  }
  .selectGridBox {
    width: 18%;
    margin: 5px;
  }
  .slick-next {
    left: 52% !important;
  }
  .circleBox {
    width: 500px;
    height: 500px;
  }
  .circleBoxNew {
    width: 500px;
    height: 500px;
  }
  .squarecontentBox {
    width: 40px !important;
  }
  .containerBox {
    padding: 10px !important;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .purpleBox {
    display: none;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 70px;
  }
}
@media (max-width: 767px) {
  .prizetext {
    min-width: 92px;
  }
  form {
    padding: 15px !important;
  }
  .lotteryquantityBox .MuiFormControl-root {
    width: 25px;
  }
  .lotteryquantityBox .MuiOutlinedInput-input {
    padding: 10.5px 12px;
    text-align: center;
  }
  .quantitytextmain {
    right: -2px !important;
  }
  .tickenumber {
    padding: 9px !important;
    width: 27px !important;
  }
  .bottomImage {
    display: none;
  }
  .numberBox {
    width: 30px;
    height: 30px;
  }
  .timelineBoxmain .MuiTimelineDot-root {
    margin-top: -4px;
    box-shadow: none;
  }
  .timelineBoxmain .MuiTimelineSeparator-root {
    margin-right: 11px;
  }
  .timelineBoxmain .MuiTimelineItem-alignAlternate:nth-child(even) {
    flex-direction: initial;
  }
  .timelineBoxmain .MuiTimelineConnector-root {
    display: none;
  }
  .timelineBoxmain .MuiTimelineOppositeContent-root {
    display: none;
  }
  .timelineBoxmain .MuiTimeline-root {
    padding: 0px;
  }
  .timelineBoxmain .MuiTimelineItem-alignAlternate:nth-child(even) .MuiTimelineItem-content {
    text-align: left;
  }
  .featuresMainBox {
    padding: 30px 0;
  }
  .featuresGrid {
    width: 70%;
    height: 200px;
  }
  .logoClientBox {
    width: auto;
    max-width: 55px;
    margin: 0 20px;
  }
  .wrapBox {
    flex-wrap: wrap;
  }
  .referalTextBox {
    text-align: left;
  }
  .mainreferral {
    margin-top: 10px;
  }
  .overlay-container {
    display: none;
  }
  .deposit-right {
    display: none;
  }
  .deposit-pay {
    width: 100% !important;
  }
  .container {
    width: 380px !important;
    overflow-x: hidden !important;
  }
  .sign-up-container {
    width: 100% !important;
  }
  .sign-in-container {
    width: 100% !important;
  }
  .container .sign-up-container {
    transform: translateX(0%) !important;
  }
  .firstNumber {
    width: 42px !important;
  }
  .footballslider .slick-next {
    right: -11px !important;
  }
  .footballslider .slick-prev {
    left: -11px !important;
  }
  .bannerimg1 {
    width: auto;
    max-width: 100px;
  }
  .numberText {
    left: -35px !important;
    top: 79px;
    width: 52px;
  }
  .albumslickbottomslider .slick-prev {
    left: 45% !important;
  }
  .buttonCustom {
    padding: 12px 0px;
  }
  .selectGridBox {
    width: 28%;
    margin: 5px;
  }
  .slick-next {
    left: 52% !important;
  }
  .hedaerTopbar {
    padding: 5px 0;
  }
  .ticketbannerImg {
    top: -28px;
  }
  .dollerImg3 {
    left: 38%;
    width: 84px !important;
    bottom: -16px;
  }
  .dollerImg2 {
    position: absolute;
    left: 45%;
    width: 117px !important;
    top: -7%;
    transform: translateX(-50%);
  }
  .dollerImg1 {
    width: 86px !important;
    top: 36%;
  }
  .TopbarButton {
    padding: 8px 24px;
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 52px;
  }
  .sliderBox img {
    width: auto;
    max-width: 135px;
  }
  .imgNoData img {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .footballslider .slick-next,
  .footballslider .slick-prev {
    width: 28px !important;
    height: 28px !important;
  }
  .footballslider .slick-next {
    margin-right: 4px;
  }
  .footballslider .slick-prev {
    margin-left: 4px;
  }
  .footballslider .slick-next:before,
  .footballslider .slick-prev:before {
    font-size: 34px !important;
    margin-top: -14px !important;
  }
}
.bannerBoxShadow {
  position: relative;
}

.bannerBoxShadow::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  background: linear-gradient(135deg, #4d8bfb 0%, #242846 100%);
  filter: blur(150px);
  z-index: -1;
  opacity: 0.34;
  transform: translate(-50%, -50%);
}

#tv-attr-logo {
  display: none !important;
}

.HlogoSignup {
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 0px;
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.6); /* Black dot */
  border-radius: 50%;
}

@keyframes pulseAnimation {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  } /* Scale up */
  100% {
    transform: scale(0.9);
  }
}
@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  } /* Move up */
  100% {
    transform: translateY(0);
  }
}
.animated-image {
  animation: pulseAnimation 1.5s infinite ease-in-out;
  cursor: pointer;
}

.animated-image:hover {
  animation-play-state: paused; /* 🔥 Stops animation on hover */
}

@keyframes shakeAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(5px);
  }
  75% {
    transform: translateY(-5px);
  }
}
.shake-animated-image {
  animation: shakeAnimation 2s ease-in-out 2; /* 🔥 Runs for 2 seconds & stops */
}

.slicktopslider .slick-prev {
  left: 0 !important;
  top: -14px !important;
  right: 60px !important;
  color: #fff !important;
  z-index: 999 !important;
  background-color: transparent !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  top: 50% !important;
}

.slicktopslider .slick-next {
  left: auto !important;
  top: -14px !important;
  right: 10px !important;
  color: #fff !important;
  z-index: 999 !important;
  background-color: transparent !important;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  top: 50% !important;
}

.text-3d {
  font-size: 18px;
  color: #ffd155;
  background: transparent;
  display: inline-block;
  text-align: center;
}

.lastSpinDate {
  position: absolute;
  left: 0%;
  top: -3.5%;
}
@media (max-height: 650px) {
  .lastSpinDate {
    top: -6%;
  }
}

@media (max-height: 650px) {
  .free-spin-countdown {
    font-size: 14px !important;
  }
}

.slicktopslider .slick-next:before,
.slicktopslider .slick-prev:before {
  font-size: 67px;
  line-height: 1;
  opacity: 0.75;
  color: #ffffff;
  margin-top: -17px;
}

.footballslider .slick-next,
.footballslider .slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 40px;
  height: 40px;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  border-radius: 50%;
  background: 0 0;
}
.footballslider .slick-prev:before {
  content: "‹";
}
.footballslider .slick-next:before {
  content: "›";
}
.footballslider .slick-prev {
  left: -41px;
  top: -20px !important;
  top: -163px !important;
  right: 60px !important;
  top: 50% !important;
  color: #fff !important;
  z-index: 999 !important;
  background-color: #edae50 !important;
  border: 2px solid #ffca64;
}
.footballslider .slick-prev:hover {
  background-color: #edae50 !important;
  border: 2px solid #ffca64;
}
.footballslider .slick-prev.slick-disabled {
  opacity: 0.5;
}
.footballslider .slick-next {
  top: 50% !important;
  bottom: -20px !important;
  left: auto !important;
  right: -41px;
  color: #fff !important;
  background-color: #edae50 !important;
  border: 2px solid #ffca64;
  z-index: 999 !important;
}
.footballslider .slick-next:hover {
  background-color: #edae50 !important;
  border: 2px solid #ffca64;
}
.footballslider .slick-next.slick-disabled {
  opacity: 0.5;
}
.footballslider .slick-next:before,
.footballslider .slick-prev:before {
  font-size: 40px;
  line-height: 1;
  opacity: 1 !important;
  color: #fff;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  margin-top: -17px;
}

form {
  background: rgb(41, 45, 79);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 30px;
  height: 100%;
  text-align: left;
  overflow: auto;
  overflow-x: hidden;
}

input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.container {
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  width: 768px;
  max-width: 100%;
  min-height: 580px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.deposit-pay {
  left: 0;
  width: 50%;
  z-index: 2;
}

.deposit-right {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.auth-modal.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.auth-modal.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%, 49.99% {
    opacity: 0;
    z-index: 1;
  }
  50%, 100% {
    opacity: 1;
    z-index: 5;
  }
}
.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.auth-modal.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: rgb(53, 58, 102);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.auth-modal.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.auth-modal.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.auth-modal.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

.noPadding .MuiDialogContent-root {
  padding: 0 !important;
  padding-top: 0 !important;
}

.textLeft {
  text-align: left !important;
}

.borderRight {
  border-right: 2px solid #2b3054;
}

.noHeight {
  height: auto;
  max-height: unset;
  border-radius: 10px;
  background: #1c1f36;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  font-size: 25px;
  cursor: pointer;
}

.googleButton > * {
  text-align: center;
  width: 100%;
  display: inline-block !important;
}

.profile_picture {
  width: 200px !important;
  height: 200px !important;
}/*# sourceMappingURL=main.css.map */