// fonts
// font-family: 'Open Sans', sans-serif;
// font-family: 'Play', sans-serif;
// font-family: 'Roboto', sans-serif;
// font-family: 'Rubik', sans-serif;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding: 0;
  margin: 0;
  opacity: 1;
  font-family: "Readex Pro", sans-serif;
  height: calc(100dvh - 16px);
  overflow: auto;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none !important;
}

// .goog-te-gadget {
//   margin-top: 1.2rem;
//   color:#191b31 ! important;
//   text-align: center;
//   /* margin-right: 1rem; */
// }

// .goog-te-gadget select {
//   color: #fff;
//   font-weight: 600;
//   width: 100%;
//   cursor: pointer;
//   padding: 1rem 0.75rem;
//   background: linear-gradient(105.04deg, #ffca64 -4.56%, #ca772a 93.4%);
//   border-radius: 100px;
//   background-repeat: no-repeat;
//   background-position: center;
//   width: 100%;
//   text-align: center;
// }
.goog-te-gadget {
  // margin-top: 1.2rem;
  color: #191b31 !important;
  text-align: center;
  /* margin-right: 1rem; */
}

.goog-te-gadget select {
  color: #fff;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  padding: 1rem 0.75rem;
  background: -webkit-linear-gradient(105.04deg, #ffca64 -4.56%, #ca772a 93.4%);
  background: linear-gradient(105.04deg, #ffca64 -4.56%, #ca772a 93.4%);
  border-radius: 100px;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
}

.goog-te-gadget select > option:checked {
  color: rgb(255, 181, 63);
}

.goog-te-gadget select > option {
  background: rgb(32, 32, 32) !important;
}

.goog-te-gadget span {
  display: none !important;
}
#google_translate-element {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  min-height: 73px;
}
@supports (-webkit-appearance: none) {
  .goog-te-gadget select {
    text-align: center;
    text-align-last: center;
  }
}

.textWhite * {
  color: white;
}

#blcktext {
  display: none;
}
#table-graphics {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../assets/imgs/table-graphics3.svg");
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
  top: 20px;
  opacity: 0.75;
  pointer-events: none;
  display: none;
}

#dark-overlay {
  position: absolute;
  width: 100%;
  // height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: 400ms;
}

// UTILITIES
:root {
  --info-rules-width: -492px;
}

.hide-element {
  display: none !important;
  // position: absolute !important;
  // overflow: hidden !important;
  // clip: rect(1px,1px,1px,1px) !important;
}

.player-left {
  opacity: 0.3;
}

.plug::before {
  position: absolute;
  transform: rotate(-30deg);
  bottom: 0;
  right: -15px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f1e6";
  z-index: 30;
  color: rgb(187, 0, 0);
  font-size: 36px;
  opacity: 1;
}

.noclick {
  pointer-events: none;
  opacity: 0.8;
}
.noclickSit {
  // pointer-events: none;
  opacity: 0.8;
  cursor: no-drop !important;
  user-select: none;
  touch-action: none;
}

.noclick2 {
  pointer-events: none;
  opacity: 0.3;
}

.noclickNew {
  opacity: 0.9;
}

.noclick-nohide {
  pointer-events: none;
  cursor: not-allowed;
}

.current-player-highlight {
  color: black !important;
  background-color: #e0e000 !important;
  transform: scale(1.1) translateX(-50%) !important;
  transition: 300ms !important;
}

#loading-icon {
  position: absolute;
  width: 50px;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
  z-index: 200;
  animation-name: loadingIcon;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes loadingIcon {
  100% {
    transform: rotateZ(1500deg);
  }
}

#loading-screen {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    rgba(53, 34, 5, 1) 12%,
    rgba(2, 1, 3, 1) 100%
  );
  z-index: 101;
}

#main-menu {
  position: relative;
  // height: 100vh;
  width: 100%;
  overflow: hidden;

  // pointer-events:auto;
  #blackjack-title {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 160px auto 0 auto;
    margin-top: 40px;
    pointer-events: none;
    max-width: 95%;
    width: 580px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f2f6f8+0,d8e1e7+50,b5c6d0+51,e0eff9+100;Grey+Gloss+%232 */
    background: #f2f6f8;
    /* Old browsers */
    background: -moz-linear-gradient(
      top,
      #f2f6f8 0%,
      #d8e1e7 50%,
      #b5c6d0 51%,
      #e0eff9 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      #f2f6f8 0%,
      #d8e1e7 50%,
      #b5c6d0 51%,
      #e0eff9 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      #f2f6f8 0%,
      #d8e1e7 50%,
      #b5c6d0 51%,
      #e0eff9 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f2f6f8', endColorstr='#e0eff9', GradientType=0);
    /* IE6-9 */

    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    span {
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#aebcbf+0,6e7774+50,0a0e0a+51,0a0809+100;Black+Gloss */
      background: #aebcbf;
      /* Old browsers */
      background: -moz-linear-gradient(
        top,
        #aebcbf 0%,
        #6e7774 50%,
        #0a0e0a 51%,
        #0a0809 100%
      );
      /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        #aebcbf 0%,
        #6e7774 50%,
        #0a0e0a 51%,
        #0a0809 100%
      );
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        #aebcbf 0%,
        #6e7774 50%,
        #0a0e0a 51%,
        #0a0809 100%
      );
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#aebcbf', endColorstr='#0a0809', GradientType=0);
      /* IE6-9 */

      -webkit-background-clip: text;
      background-clip: text;
      color: white;
    }
  }
}

// -------------------****------------------
/*styles for the header */

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 10px 16px;

  img {
    width: 100%;
    max-width: 133px;

    @media (max-width: 914px) {
      max-width: 80px;
    }
  }

  @media (max-width: 886px) {
    padding: 100px 10px 0px;
  }
}

.logo-container {
  position: absolute;
  margin: 0 auto;
  top: 11px;
  left: 50%;
  transform: translateX(-50%);
}

.drawer-btn {
  display: none;
}

@media (max-width: 886px) {
  .header {
    flex-wrap: wrap;
    justify-content: center;
  }

  .imgBox {
    margin-bottom: 10px;
  }

  .right-buttons {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }

  .drawer-btn {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    transition: transform 0.3s ease-in-out;
  }

  .drawer-btn span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin-bottom: 5px;
    transition: transform 0.3s ease-in-out;
  }

  /* Drawer*/
  .drawer-open .header {
    transform: translateY(-100%);
  }

  .drawer-open .drawer-btn {
    transform: rotate(180deg);
  }

  .drawer-open .drawer-btn span {
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
  }
}

.back-btn {
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  padding: 9px 12px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.04);

  @media (max-width: 886px) {
    margin-bottom: 10px;
  }
}

.right-buttons {
  display: flex;
  gap: 10px;
}

.usdt-btn,
.address-btn,
.how-to-play-btn {
  font-size: 18px;
  font-weight: 400;
  padding: 9px 12px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.04);
  color: #fff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid transparent;

  .walletimg {
    margin-right: 10px;
    width: 100%;
    max-width: 24px;

    @media (max-width: 420px) {
      margin-right: 6px;
      max-width: 16px;
    }
  }

  @media (max-width: 1067px) {
    font-size: 14px;
    font-weight: 400;
    padding: 5px 11px;
  }

  @media (max-width: 1000px) {
    font-size: 14px;
    font-weight: 400;
    padding: 5px 11px;
  }

  @media (max-width: 420px) {
    font-size: 13px;
  }
}

.usdt-btn:hover,
.address-btn:hover,
.how-to-play-btn:hover {
  border: 1px solid #ea9f28;
}

// ----------------****-------------------

#a_offline {
  text-decoration-line: none;
}

#main-box .play-btns {
  font-weight: 400;
  display: flex;
  align-items: center;
  margin: auto;
  width: auto;
  padding: 13px 42px;
  border-radius: 7.125rem;
  color: white;
  border: none;
  background: linear-gradient(105.04deg, #ffca64 -4.56%, #ca772a 93.4%);
  font-size: 14px;
  justify-content: center;
  margin-top: 44px;
  cursor: pointer;
}

#main-box #btnCreate,
#main-box #btnJoin {
  background: linear-gradient(105.04deg, #ffca64 -4.56%, #ca772a 93.4%);
}
#eyeHover{
  display:block
}
#main-box {
  position: relative;
  width: 361px;
  /* height: 500px; */
  border: 1px solid #77501433;
  margin: 180px auto;
  margin: 50px auto 0 auto;
  text-align: center;
  border-radius: 10px;
  background-color: #1d1f30;
  box-shadow: 0px 0px 50px -20px rgba(0, 0, 0, 0.75);
  z-index: 3;

  div {
    margin: 35px 0px 40px 0px;

    h3 {
      font-size: 18px;
      font-weight: 300;
    }
  }

  #avatar-box {
    display: flex;
    justify-content: center;
    align-items: center;

    // padding-bottom: 4px;
    i {
      color: black;
      padding: 6px;
      font-weight: 700;
      font-size: 18px;
    }

    button:nth-of-type(1) i {
      margin-right: 6px;
    }

    button:nth-of-type(2) i {
      margin-left: 6px;
    }

    .slideAvatars {
      position: relative;
      border: 2px solid #d97c27;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      width: 115px;
      height: 115px;
      overflow: hidden;

      display: flex !important;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        border-radius: 8px;
      }

      .numbertext {
        position: absolute;
        left: 50%;
        bottom: -38px;
        font-size: 12px;
        transform: translateX(-50%);
        color: white;
        opacity: 0.8;
      }
    }

    button {
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;
      padding: none;
      // border: 1px solid red;
    }

    button:hover i {
      color: rgba(255, 255, 255, 0.8);
    }

    button:active i {
      transform: scale(0.9);
    }
  }

  input {
    width: 45%;
    padding: 6px 0;
    font-family: "Readex Pro", sans-serif;
    font-weight: 600;
    font-size: 20px;
    border: none;
    outline: none;
    outline: 1px solid rgba(255, 255, 255, 0);
    border-radius: 2px;
    transition: 100ms;
  }

  input:focus {
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.5);
  }

  .play-btns {
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: auto;
    width: auto;
    padding: 13px 42px;
    border-radius: 7.125rem;
    color: white;
    border: none;
    background: linear-gradient(164deg, #ffca64 0%, #ca772a 100%);
    font-size: 16px;
    justify-content: center;
    margin-top: 44px;
    cursor: pointer;

    span i {
      display: flex;
      align-items: center;
      justify-content: center;
      // position: absolute;
      font-size: 20px;
      // border: 1px solid black;
      width: 38px;
    }
  }

  #btnOffline {
    margin-top: 40px;
    background-color: #143853;
  }

  #btnOffline:hover {
    background-color: #0d2436;
  }

  #btnCreate,
  #btnJoin {
    background-color: rgb(199, 151, 29);
  }

  #btnCreate:hover,
  #btnJoin:hover {
    background-color: rgb(158, 117, 13);
  }
}

#info-rules {
  position: absolute;
  right: var(--info-rules-width);
  top: 10px;
  height: 90%;
  width: 500px;
  color: white;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(26px);
  background-clip: padding-box;
  border-radius: 0px 0px 0px 22px;
  padding: 0 0 0 12px;
  opacity: 1;
  transition: 300ms;
  z-index: 300;
  box-shadow: 0px 0px 15px 0px rgba(45, 45, 45, 0.2);

  h1 {
    color: white;
    border-top: 1px solid #ffca64;
    font-family: "Cinzel", serif;
  }

  p,
  li {
    padding: 7px 0px;
    color: rgba(255, 255, 255, 0.6);
  }

  #h1margin {
    margin-top: 64px;
    border-top: 0px solid rgba(255, 255, 255, 0);
  }
}

#info-rules-overflow {
  height: 100%;
  width: 100%;
  padding: 0 12px 0 0;
  overflow: auto;
}

#info-rules-overflow ul {
  font-size: 14px !important;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;
}

#info-rules-overflow li {
  font-size: 14px !important;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;
  margin-bottom: 4px;
}

#modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

#info-rules-overflow h3 {
  font-family: "Cinzel", serif;
  font-size: 14px !important;
  font-weight: 600 !important;
}

#info-rules-overflow h1 {
  font-size: 30px !important;
  color: rgb(255, 255, 255);
  font-weight: 600;
  text-align: left !important;
  font-family: "Cinzel", serif;
  margin-bottom: 20px !important;
}

#info-modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1c1f36;
  background-clip: padding-box;
  padding: 20px;
  max-width: 990px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  opacity: 1;
  transition: 300ms;
  z-index: 9999;
  color: #fff;
  border-radius: 0px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

/* Add any additional styling for the info box content */

#info-rules-overflow {
  margin-top: 10px;

  /* Add margin to separate the content from the close button */
  h3 {
    font-family: "Cinzel", serif;
    font-size: 16px;
    font-weight: 500;
  }

  p {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 300;
    margin: 10px 0px 20px;
  }

  h1 {
    font-size: 40px;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    text-align: center;
    font-family: "Cinzel", serif;
    margin-bottom: 25px;
  }
}

#info-rules-overflow h3,
#info-rules-overflow p {
  // margin-bottom: 10px;
  font-size: 14px !important;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300 !important;
  margin: 10px 0px 20px;
}

/* Rest of the CSS remains the same */

#how-to-play {
  top: 0;
  backdrop-filter: blur(26px);
  text-shadow: 1px 1px black;
  cursor: pointer;
  z-index: 300;
  margin-top: 10px;
  margin-left: 10px;
  @media (max-width: 1000px) {
    white-space: nowrap;
    font-size: 14px;
    height: 31px;
    display: none;
  }
}

#how-to-play:hover {
  color: rgb(204, 204, 204);
}

// #how-to-play:after {
//   content: "";
//   width: 32px;
//   height: 32px;
//   border: 10px solid #0f2225;
//   position: absolute;
//   bottom: -32px;
//   border-top: 0;
//   pointer-events: none;
//   z-index: 1;
// }
#how-to-play::after {
  border-right: 0;
  border-radius: 0 0 0 30px;
  right: -7px;
  transform: rotate(180deg);
  pointer-events: none;
}

#about-box {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  left: 0;
  right: 0;
  width: 476px;
  padding: 12px;
  margin: 0 auto;
  border-radius: 0px 0px 8px 0px;
  background-color: rgb(233, 233, 233);
  top: -196px;
  z-index: 0;
  transition: 250ms;

  ul {
    // list-style-type: none;
    padding: 12px 0px 12px 20px;
    margin: 0;
  }
}

#about {
  position: absolute;
  cursor: pointer;
  font-weight: 700;
  background-color: rgb(233, 233, 233);
  border: none;
  outline: none;
  padding: 3px 32px;
  border-radius: 0px 0px 12px 12px;
  bottom: -21px;
  left: 0;
}

#about:after {
  content: "";
  width: 11px;
  height: 11px;
  border: 6px solid rgb(233, 233, 233);
  position: absolute;
  top: -6px;
  border-top: 0;
  z-index: -1;
}

#about::after {
  border-right: 0;
  border-radius: 0 0 0 12px;
  right: -11px;
  transform: rotate(90deg);
}

#credits {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: hsla(192, 100%, 67%, 0.8);
  font-size: 14px;
  margin-bottom: 10px;
  text-decoration-line: none;
}

#credits:hover {
  color: hsla(192, 52%, 52%, 0.8);
  text-decoration-line: underline;
}

#credits-container {
  color: white;

  a {
    color: hsla(212, 100%, 67%, 0.8);
  }
}

// #############GAME MENU#############
#main-box div {
  margin: 30px 0;
}

#balance-bet-box .balance-bet {
  margin-top: 8px;
  margin-right: 10px;
}

#game-room {
  // height: calc(100vh - 108px);
  height: calc(100dvh - 74px);
  overflow: hidden;
  // background-image: url("blackjackbackground.png");
  // background-attachment: fixed;
  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
  position: relative;
  background: #010403;
}
.bg_image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#game-room::after {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 21%;
  background: linear-gradient(rgb(2 21 15), rgb(1 13 10));
}

#main-box #avatar-box .slideAvatars img {
  /* max-width: 100%; */
  // max-width: max-content;
  border-radius: 50px !important;
  width: auto !important;
}

.header-enter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 47px;
}

#header-left {
  display: flex;
  align-items: center;
  margin-left: 28px;
  @media (max-width: 1000px) {
    // display: none;
  }
}

#header-right {
  display: flex;
  align-items: center;
  @media (max-width: 1000px) {
    justify-content: space-between;
    margin-top: 0px;
    width: 100%;
    margin-left: 42px;
  }
}

@media (max-width: 1000px) {
  .header-enter {
    flex-direction: column;
  }

  #header-left {
    margin-bottom: 10px;
    justify-content: space-between;
    width: 100%;
  }

  // #header-right {
  //   margin-top: 30px;
  //   justify-content: center;
  // }
}

#users-online-box {
  position: absolute;
  width: 300px;
  left: -258px;
  top: 80px;
  background-color: rgba(26, 25, 25, 0.88);
  color: white;
  transition: 300ms;
  border-radius: 0px 6px 6px 0px;
  z-index: 201;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  backdrop-filter: blur(26px);
  border: 1px solid rgba(255, 255, 255, 0.08);

  @media (max-width: 1000px) {
    // #users-online-box {
    top: 62px !important;
    z-index: 301;
    // }
  }

  #users-online-label {
    text-shadow: 1px 1px black;
    font-size: 14px;
    font-weight: 300;
    font-family: "Readex Pro", sans-serif;

    margin: 12px 40px 12px 7px;
  }

  .user-list-name {
    font-family: "Readex Pro", sans-serif;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10+ and Edge */
    user-select: none;
    /* Standard syntax */
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 300;
  }

  .flex-room {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
  }

  .flex-box {
    display: flex;
    align-items: center;

    img {
      width: 100%;
      max-width: 25px;
    }
  }

  #users-online-button {
    position: absolute;
    right: 0;
    width: 54px;
    height: 46px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
      transition: 200ms;
    }
  }

  #users-online-button:hover {
    color: rgb(204, 204, 204);
  }

  ul {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-size: 15px;
    // border-top: 2px solid #d84a17c0;
    padding: 10px;

    .users-list-box {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 0px;
      border-radius: 10px;
      // border-top: 2px solid #ffca64;
      background-color: rgba(255, 255, 255, 0.04);

      .users-list-info {
        // display: inline-block;
        margin-right: 17px;

        span {
          color: rgba(255, 202, 100, 1);
        }
      }

      .users-list-img {
        position: absolute;
        left: 0;
        border: 2px solid rgba(255, 255, 255, 0.25);
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        width: 24px;
        height: 24px;
        margin: 4px;
        border-radius: 50px;

        img {
          max-width: 100%;
          max-height: 100%;
          border-radius: 50px;
          pointer-events: none;
        }
      }
    }
  }
}
.balancetextnotneed {
  // display: none;
}
.show-message-div {
  display: flex;
  justify-content: center;
}
.show-message-pop {
  min-width: 300px;
  max-width: 500px;
}
#invite-link-box {
  position: relative;
  margin-right: 10px;
  width: 260px;
  height: 45px;
  background-color: rgba(255, 255, 255, 0.03);
  margin-left: 18px;
  margin-top: 10px;
  z-index: 1;
  border-radius: 50px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(85, 63, 38, 1);

  input {
    text-align: center;
  }

  #invite-link {
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.03);
    color: white;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    text-align: center;
  }

  &:hover #invite-link {
    display: block;
  }

  &:hover #invite-label {
    opacity: 0;
  }

  #invite-label {
    position: absolute;
    // background-color: white;
    // height: 100%;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0px 10px;
    background-color: #202421e0;
    color: rgba(255, 255, 255, 0.6);
    pointer-events: none;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10+ and Edge */
    user-select: none;
    /* Standard syntax */
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    font-weight: 300;
    opacity: 1;
  }

  #invite-label:hover {
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
  }

  button {
    background: linear-gradient(105.04deg, #ffca64 -4.56%, #ca772a 93.4%);
    color: white;
    height: 100%;
    width: 60px;
    border: none;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  button:hover {
    background: linear-gradient(105.04deg, #ffca64 -4.56%, #ca772a 93.4%);
  }
}

#volume-button {
  position: absolute;
  width: 42px;
  height: 42px;
  background-color: rgba(4, 14, 59, 0.5);
  // left: 440px;
  right: 160px;
  // margin-top: 6px;
  margin-top: 10px;
  z-index: 200;
  border: 1px solid black;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  i {
    color: white;
    font-size: 18px;
  }

  .fa-volume-mute {
    color: rgb(255, 147, 147);
  }
}

#bg-color-box {
  position: absolute;
  width: 260px;
  height: 42px;
  right: 215px;
  margin-top: 10px;
  z-index: 200;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bg-colors {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    border: 1px solid rgb(0, 0, 0);
    cursor: pointer;
  }

  #bg-green {
    background-color: #265328af;
  }

  #bg-blue {
    background-color: #274f55af;
  }

  #bg-purple {
    background-color: #293b5aaf;
  }

  #bg-red {
    background-color: #582828af;
  }

  #bg-brown {
    background-color: #534e26af;
  }
}

.bg-selected {
  border: 1px solid white !important;
}

#leave-button {
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
  height: 45px;
  background: rgba(43, 42, 42, 0.4);
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 1);
  // border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 50px;
  font-weight: 400;
  width: fit-content;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  z-index: 10;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */
  padding: 0px 11px;
  border: 1px solid transparent;

  i {
    transform: rotate(180deg);
  }

  @media (max-width: 1000px) {
    white-space: nowrap;
    font-size: 14px;
    height: 31px;
  }
}
#room-name {
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
  height: 45px;
  background: rgba(43, 42, 42, 0.4);
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 1);
  border-radius: 50px;
  font-weight: 400;
  width: fit-content;
  font-size: 16px;
  text-align: center;
  z-index: 10;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0px 11px;
  border: 1px solid transparent;

  i {
    transform: rotate(180deg);
  }

  @media (max-width: 1000px) {
    white-space: nowrap;
    font-size: 14px;
    height: 31px;
  }
}
#room-name img {
  // position: relative;
  // margin-left: 6px;
  // width: 30px;
  // height: 30px;
  // border-radius: 50px;
  // overflow: hidden;
  // pointer-events: none;
  @media (max-width: 1000px) {
    width: 12px;
    height: 12px;
  }
}
#pot-amount {
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
  height: 45px;
  background: rgba(43, 42, 42, 0.4);
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 1);
  // border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 50px;
  font-weight: 400;
  width: fit-content;
  // cursor: pointer;
  font-size: 16px;
  text-align: center;
  z-index: 10;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */
  padding: 0px 11px;
  border: 1px solid transparent;

  i {
    transform: rotate(180deg);
  }

  @media (max-width: 1000px) {
    white-space: nowrap;
    font-size: 14px;
    height: 31px;
    font-size: 12px;
    margin-left: -18px;
    margin-top: 45px;
  }

  @media (max-width: 991px) {
    font-size: 12px !important;
    height: 31px;
    position: absolute;
    background: rgba(43, 42, 42, 0.9);
    left: calc(50% + 60px);
        top: 0px;
        font-weight: bold;
  }
}

.usdt-button,
.address-button,
.how-to-play-button {
  font-size: 18px;
  font-weight: 400;
  padding: 9px 12px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.04);
  color: #fff;
  border: none;
  cursor: pointer;
  border: 1px solid transparent;
  margin-right: 10px;
  display: flex;
  align-items: center;

  .walletimg {
    margin-right: 10px;
    width: 100%;
    max-width: 24px;

    @media (max-width: 420px) {
      margin-right: 6px;
      max-width: 16px;
      font-size: 12px;
    }
  }

  @media (max-width: 1000px) {
    font-size: 14px;
  }
}
.leaderboard-button {
  font-size: 18px;
  font-weight: 400;
  padding: 9px 12px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.04);
  color: #fff;
  border: none;
  cursor: pointer;
  border: 1px solid transparent;
  margin-right: 20px;
  display: flex;
  align-items: center;

  .walletimg {
    margin-right: 10px;
    width: 100%;
    max-width: 24px;

    @media (max-width: 420px) {
      margin-right: 6px;
      max-width: 16px;
      font-size: 12px;
    }
  }

  @media (max-width: 1000px) {
    font-size: 13px;
    margin-right: 32px;
  }
}

.usdt-button:hover,
.address-button:hover,
.how-to-play-button:hover {
  border: 1px solid #ea9f28;
}
.leaderboard-button:hover {
  border: 1px solid #ea9f28;
}

// }
#leave-button:hover {
  color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(85, 63, 38, 1);
}

#leave-button:active {
  color: white;
  border: 1px solid rgba(85, 63, 38, 1);
}

@media (max-width: 420px) {
  #leave-button,
  #usdt-button,
  #address-button,
  #how-to-play-button {
    font-size: 14px;
    padding: 7px 10px;
  }
  #leaderboard-button {
    font-size: 14px;
    padding: 7px 10px;
  }

  .walletimg {
    max-width: 16px;
  }

  #invite-link-box {
    width: 100%;
    max-width: 300px;
    margin-left: 0;
  }

  #bg-color-box {
    right: 10px;
  }
}

@media (max-width: 320px) {
  #leave-button,
  #usdt-button,
  #address-button,
  #how-to-play-button {
    font-size: 12px;
    padding: 5px 8px;
  }
  #leaderboard-button {
    font-size: 12px;
    padding: 5px 8px;
  }

  .walletimg {
    max-width: 14px;
  }
}

#leave-table {
  position: absolute;
  bottom: 40px;
  right: 10px;
  // margin-left: 10px;
  padding: 0px 6px;
  height: 30px;
  background: rgba(43, 42, 42, 0.4);
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 0.6);
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  font-weight: 700;
  width: fit-content;
  cursor: pointer;
  text-align: center;
  z-index: 10;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */
  border: 1px solid #ff6464;

  i {
    color: lime;
  }
}

#leave-table:hover {
  color: rgba(255, 255, 255, 0.8);
}

#leave-table:active {
  color: white;
}

#player-result-big {
  position: absolute;
  width: 400px;
  height: 80px;
  top: 54%;
  margin-top: -210px;
  left: 50%;
  margin-left: -200px;
  font-size: 18px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.25);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 20%,
    rgba(0, 0, 0, 0.25) 50%,
    rgba(0, 0, 0, 0.2) 80%,
    rgba(0, 0, 0, 0) 100%
  );
  text-shadow: 0 0 10px black;
  z-index: 50;
  pointer-events: none;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */
}

.color-green {
  color: rgb(0, 222, 0) !important;
}

.color-red {
  color: rgb(242, 0, 0) !important;
}

#join-mid-game-label {
  position: absolute;
  left: 50%;
  top: 50%;
  display: inline-block;
  font-size: 22px;
  color: white;
  transform: translateX(-50%);
  text-align: center;
}

.newZIndex{
  z-index: 99999;}

#deal-start-label {
  position: absolute;
  left: 50%;
  margin-top: 240px;
  display: inline-block;
  font-size: 36px;
  color: white;
  transform: translateX(-50%);
  text-align: center;
  @media (max-width: 1000px) {
    // font-size: 8px;
    margin-top: -69px;
  }
}
#deal-start-label p {
  color: #ffca64;
  @media (max-width: 1000px) {
    font-size: 14px;
    color: #fff;
    // margin-top: 40px;
  }
}

#players-container {
  position: absolute;
  width: 80%;
  height: 48%;
  // background: #063;
  bottom: 0px;
  left: 50%;
  margin-left: -40%;
  display: flex;
  /* flex-flow: wrap; */
  /* flex-direction: row; */
  // background-color: green;
  color: black;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}

.players {
  position: relative;
  // background: white;
  width: 90px;
  height: 90px;
  border: 2px solid transparent;
  stroke-dasharray: 1000;
  stroke-dashoffset: 500;
  border-radius: 100%;
  // background-color: rgba(0, 0, 0, 0.2);
  // background-color: #fdc963;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */
}

#players-timer-container {
  position: absolute;
  width: 80%;
  height: 48%;
  bottom: 0;
  left: 50%;
  margin-left: -40%;
  display: flex;
  color: black;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
}

.players-timer {
  position: relative;
  width: 90px;
  height: 90px;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 100%;
  transform: rotate(-90deg);
  .circle-animation {
    stroke: lime;
    stroke-dasharray: 301;
    stroke-dashoffset: 301;
    animation: dash 35s linear forwards;
    border: 1px solid rgba(255, 255, 255, 0);
  }
  @media all and (max-width: 992px) {
    transform: rotate(-90deg);
  }
  @media (max-width: 1300px) {
    font-size: 18px;
  }
  @media (max-width: 1000px) {
    font-size: 16px;
  }
  @media (max-width: 600px) {
    width: 80px;
    height: 80px;
    font-size: 14px;
  }
}

@keyframes dash {
  100% {
    stroke-dashoffset: 0;
    stroke: red;
  }
}

// .players:nth-of-type(4) {
//   margin-bottom: 100px;
// }

.players:nth-of-type(1),
.players:nth-of-type(5) {
  margin-bottom: 250px;
}

.players:nth-of-type(2),
.players:nth-of-type(4) {
  margin-bottom: 65px;
}

.players:nth-of-type(3) {
  margin-bottom: 225px;
}
// -------------------  ------------------
.players-timer:nth-of-type(1) {
  margin-bottom: 250px;
  @media (max-width: 992px) {
    margin-bottom: 105px;
  }
}
.players-timer:nth-of-type(5) {
  margin-bottom: 250px;
}

.players-timer:nth-of-type(2) {
  margin-bottom: 174px;
  @media (max-width: 992px) {
    margin-bottom: 63px;
  }
}

.players-timer:nth-of-type(4) {
  margin-bottom: 174px;
  // margin-left: 6px
}
// .players-timer:nth-of-type(1),
// .players-timer:nth-of-type(5) {
//   margin-bottom: 250px;
// }

// .players-timer:nth-of-type(2),
// .players-timer:nth-of-type(4) {
//   margin-bottom: 175px;
// }

.players-timer:nth-of-type(3) {
  margin-bottom: 225px;
  @media (max-width: 992px) {
    margin-bottom: 110px;
  }
}

.player-name {
  position: absolute;
  top: 100px;
  text-shadow: 1px 1px 10px black;
  font-size: 17px;
  color: white;
  pointer-events: none;
  left: -40%;
  font-family: "Readex Pro", sans-serif;
  width: 150px;
  text-align: center;
  // @media (max-width: 576px) {
  //   font-size: 10px;
  //   left: -35%;
  //   top: 62px;
  //   width: 100px;
  //   // height: 31px;
  // }
}

.highlight {
  color: rgba(255, 202, 100, 1);
}

.player-name span {
  position: relative;
  margin-left: 6px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  overflow: hidden;
  pointer-events: none;
  @media (max-width: 576px) {
    width: 19px;
    height: 19px;
  }
}

.player-avatar {
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  object-fit: contain;
  pointer-events: none;
}

.player-sum {
  position: absolute;
  // width: 34px;
  margin: 0 auto;
  padding: 4px 8px;
  // height: 30px;
  top: -45px;
  // left: 50%;
  // margin-left: -17px;
  color: rgb(224, 224, 0);
  background: rgba(0, 0, 0, 0.8);
  // border: 1px solid black;
  border-radius: 4px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  display: inline-block;
  pointer-events: none;
  z-index: 15;
  opacity: 0;
  transform: translateX(-50%) !important;
  transition: 100ms ease-in-out;
  text-align: center;
  font-family: "Cinzel", serif;

  left: 50%;
  /* allow the width to be calculated dynamically */
  width: auto;
}

.player-result {
  position: absolute;
  height: 50px;
  width: 50px;
  top: -110px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -27px;
  font-size: 15px;
  font-weight: 700;
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid black;
  background: rgb(31, 31, 31);
  background: linear-gradient(
    0deg,
    rgba(31, 31, 31, 1) 0%,
    rgba(65, 65, 65, 1) 50%,
    rgba(34, 34, 34, 1) 100%
  );
  border-radius: 100%;
  // border: 2px solid rgb(245, 245, 245);
  // color: rgb(245, 245, 245);
  // text-shadow: 0 0 4px gray;
  box-shadow: 0 0 5px black;
  pointer-events: none;
  z-index: 20;
}

.result-lose {
  color: rgb(242, 0, 0);
  border: 2px solid rgb(242, 0, 0);
  text-shadow: 0 0 4px rgb(142, 0, 0);
}

.result-draw {
  border: 2px solid rgb(200, 200, 200);
  color: rgb(200, 200, 200);
  text-shadow: 0 0 4px rgb(100, 100, 100);
}

.result-win {
  border: 2px solid rgb(0, 222, 0);
  color: rgb(0, 222, 0);
  text-shadow: 0 0 4px rgb(0, 122, 0);
}

.result-blackjack {
  border: 2px solid gold;
  color: gold;
  text-shadow: 0 0 4px rgb(155, 115, 0);
  font-size: 17px;
}

.placebetImg {
  background: url("../assets/imgs/chips/placebid.svg") no-repeat;
}

.empty-slot {
  background: url("../assets/imgs/chips/newbet.svg") no-repeat;
  background-size: contain;
}

// .player-coin {
//   position: absolute;
//   width: 70px;
//   height: 70px;
//   left: 50%;
//   margin-left: -35px;
//   top: 50%;
//   margin-top: 200px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   pointer-events: none;
//   // background: url("") no-repeat;
//   font-family: "Cinzel", serif;
//   text-shadow: 1px -1px grey;
//   font-size: 16px;
//   opacity: 0;
//   font-weight: 700;
//   color: black;
//   // background-color: rgba(0, 0, 0, 0.2);
//   transform: rotate(-360deg);
// }

.player-coin {
  position: absolute;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  left: 0px;
  margin-left: -4px;
  top: 0px;
  margin-top: -4px;
  opacity: 0;
  // background-color: rgba(0, 0, 0, 0.2);
  transform: rotate(-360deg);
}
.players-img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1 !important;
  border: 2px solid #fdc963;
    border-radius: 50%;
}

.player-coin-animation {
  position: absolute !important;
  opacity: 1 !important;
  transition: 200ms !important;
  transform: rotate(0deg) !important;
  display: block !important;
  z-index: 100 !important;
}

.empty-slot {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  border-radius: 100%;
  border: 2px solid rgba(255, 255, 255, 0);
  color: rgb(0, 255, 0);
  cursor: pointer;
  background-color: rgb(0 0 0 / 91%);
}

.empty-slot:hover {
  background: rgba(43, 42, 42, 0.4);
  border: 1px solid #553f26;
}

.empty-slot i {
  display: none;
  text-shadow: 0 0 4px rgb(0, 255, 0);
}

i {
  background-size: 50px 100px;
}
.noclickSit:hover {
  // background: none !important;
  // border: none !important;
  // background: url(/imgs/chips/newbet.svg) no-repeat;
  background: url("../assets/imgs/chips/newbet.svg") no-repeat !important;
  background-size: contain !important;
  background-color: rgba(0, 0, 0, 0.91) !important;
}
.player-cards {
  position: relative;
  // background-color: rgba(255, 0, 0, 0.281);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 0;
}

.players:nth-of-type(1) .player-cards {
  transform: rotate(15deg);
}

.players:nth-of-type(2) .player-cards {
  transform: rotate(10deg);
}

// .players:nth-of-type(3) .player-cards {
//   transform: rotate(5deg);
// }

.players:nth-of-type(4) .player-cards {
  transform: rotate(-10deg);
}
.players:nth-of-type(5) .player-cards {
  transform: rotate(-15deg);
}

// .players:nth-of-type(1) .player-cards {
//   transform: rotate(15deg);
// }

// .players:nth-of-type(2) .player-cards {
//   transform: rotate(10deg);
// }

// .players:nth-of-type(3) .player-cards {
//   transform: rotate(5deg);
// }

// .players:nth-of-type(5) .player-cards {
//   transform: rotate(-5deg);
// }

// .players:nth-of-type(6) .player-cards {
//   transform: rotate(-10deg);
// }

// .players:nth-of-type(7) .player-cards {
//   transform: rotate(-15deg);
// }

.cardImg {
  position: absolute;
  opacity: 1;
  top: -136px;
  left: 2.5px;
  width: 90px;
  height: 135px;
  transform-origin: top left;
  transition: 500ms;
  @media (max-width: 1000px) {
    top: -80px;
    width: 70px;
    height: auto;
  }
  @media (max-width: 500px) {
    top: -50px;
    width: 40px;
    height: auto;
  }
}

// Card Seperation
.card1 {
  z-index: 1;
  @media (max-width: 1000px) {
    top: -70px;
  }
}

.card2 {
  z-index: 2;
  margin-left: 20px;
  top: -156px;
  @media (max-width: 1000px) {
    top: -96px;
    margin-left: 10px;
  }
  @media (max-width: 500px) {
    top: -61px;
    margin-left: 10px;
  }
}

.card3 {
  z-index: 3;
  margin-left: 40px;
  top: -176px;
  @media (max-width: 1000px) {
    top: -116px;
    margin-left: 20px;
  }
  @media (max-width: 900px) {
    top: -71px;
    margin-left: 26px;
  }
  @media (max-width: 500px) {
    top: -71px;
    margin-left: 20px;
  }
}

.card4 {
  z-index: 4;
  margin-left: 60px;
  top: -196px;
  @media (max-width: 1000px) {
    top: -135px;
    margin-left: 30px;
  }
  @media (max-width: 900px) {
    top: -76px;
    margin-left: 41px;
  }
  @media (max-width: 500px) {
    top: -81px;
    margin-left: 30px;
  }
}

.card5 {
  z-index: 5;
  margin-left: 80px;
  top: -216px;
  @media (max-width: 1000px) {
    top: -166px;
    margin-left: 40px;
  }
  @media (max-width: 992px) {
    top: -81px;
    margin-left: 51px;
  }
  @media (max-width: 500px) {
    top: -81px;
    margin-left: 40px;
  }
}

.card6 {
  z-index: 6;
  margin-left: 100px;
  top: -236px;
  @media (max-width: 1000px) {
    top: -186px;
    margin-left: 71px;
  }
  @media (max-width: 500px) {
    top: -91px;
    margin-left: 50px;
  }
}

.card7 {
  z-index: 7;
  margin-left: 120px;
  top: -256px;
  @media (max-width: 1000px) {
    top: -106px;
    margin-left: 91px;
  }
  @media (max-width: 500px) {
    top: -101px;
    margin-left: 60px;
  }
}

.card8 {
  z-index: 8;
  margin-left: 140px;
  top: -276px;
  @media (max-width: 1000px) {
    top: -226px;
    margin-left: 110px;
  }
  @media (max-width: 500px) {
    top: -111px;
    margin-left: 70px;
  }
}

.card9 {
  z-index: 9;
  margin-left: 160px;
  top: -296px;
  @media (max-width: 1000px) {
    top: -246px;
    margin-left: 120px;
  }
  @media (max-width: 500px) {
    top: -121px;
    margin-left: 80px;
  }
}

.card10 {
  z-index: 10;
  margin-left: 180px;
  top: -316px;
  @media (max-width: 1000px) {
    top: -266px;
    margin-left: 130px;
  }
  @media (max-width: 500px) {
    top: -131px;
    margin-left: 90px;
  }
}

.card11 {
  z-index: 11;
  margin-left: 200px;
  top: -336px;
  @media (max-width: 1000px) {
    top: -286px;
    margin-left: 140px;
  }
  @media (max-width: 500px) {
    top: -141px;
    margin-left: 100px;
  }
}

// Card Seperation

.dealerCardImg {
  width: 90px;
  height: 135px;
  transition: 500ms;
  @media (max-width: 1000px) {
    top: -86px;
    width: 50px;
    height: 75px;
  }
}

.cardAnimation {
  position: absolute !important;
  top: -600px !important;
  transform: rotateZ(90deg) !important;
  opacity: 0 !important;
}

.cardAnimationDealer {
  margin-left: 200px !important;
  // right: 0 !important;
  // left: 300px !important;
  // padding-right: 100px !important;
  transform: rotateZ(90deg) !important;
  opacity: 0 !important;
}

// .cardAnimationDealerHidden {
//   padding-right: 100px !important;
//   left: 200px !important;
//   margin-left: 500px !important;
//   transform: rotateZ(90deg) !important;
//   opacity: 0 !important;
// }

#balance-bet-box {
  position: absolute;
  display: flex;
  bottom: 40px;
  width: 200px;
  color: white;
  text-align: center;
  border-radius: 10px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.balance-bet {
  background-color: rgba(43, 42, 42, 0.4);
  padding: 4px;
  border: 1px solid white;
  border-radius: 50px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #ffca64;
  padding: 9px 17px;
  white-space: nowrap;
  width: 100%;
  min-width: 140px;
  justify-content: center;
  height: 40px;
  font-weight: 300;

  #total-bet {
    color: rgb(224, 224, 0);
  }
  @media (max-width: 1000px) {
    white-space: nowrap;
    font-size: 14px;
    height: 31px;
  }
}

#dealer {
  position: relative;
  margin: 0 auto;
  // height: 150px;
  // display: flex;
  display: none;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-family: "Cinzel", serif;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;

  /* Standard syntax */
  h1 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 26px;
    color: white;
    top: 10px;
    text-shadow: 0 0 10px black;
    margin: 0;
    padding: 0;

    // margin-top: 50px;
    @media (max-width: 1000px) {
      margin-top: 50px;
    }
  }

  #dealerSum {
    display: none;
    position: absolute;
    // font-size: 16px;
    // width: 34px;
    padding: 4px 8px;
    // height: 30px;
    top: 140px;
    // left: 50%;
    // margin-left: -17px;
    color: rgb(224, 224, 0);
    background: rgba(0, 0, 0, 0.8);
    // border: 1px solid black;
    border-radius: 4px;
    // display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    pointer-events: none;
    z-index: 4;
    opacity: 0;
    transition: 100ms ease-in-out;
    transform: translateX(-50%) !important;
    left: 50%;
    /* allow the width to be calculated dynamically */
    width: auto;
  }

  .dealer-cards {
    display: flex;
    flex-direction: row;
    position: relative;
    left: 50%;
    width: 100%;
    transition: 500ms;
    display: none;
  }

  .visibleCards {
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: row;
    // justify-content: flex-start;
    width: 100%;
    transition: 500ms;
  }

  // .hiddenCard {
  //   position: relative;
  //   // display: flex;
  //   // height: 150px;
  //   // width: 100px;
  //   // text-align: center;
  //   // margin: 0 auto;
  //   transition: 500ms;
  //   img {
  //     width: 100%;
  //     height: 100%;
  //     object-fit: cover;
  //     border-radius: 12px;
  //   }
  // }
}

#bets-container {
  position: absolute;
  width: 225px;
  height: 216px;
  // margin: 0 auto;
  bottom: 0;
  left: 160px;
  // transform: translateX(-50%);
  display: flex;
  color: black;
  // text-align: center;
  // justify-content: space-evenly;
  align-items: center;
  padding-top: 0px;
  // padding-bottom: 20px;

  button {
    position: relative;
    width: 35px;
    height: 35px;
    // background-size: cover;
    display: inline-block;
    color: rgb(0, 0, 0);
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px black;
    cursor: pointer;
    border: none;
    outline: none;
    font-weight: 700;
    font-size: 16px;
    text-shadow: 1px -1px gray;
    transform: scale(1.3);
    transition: transform 1s;
  }

  button:hover {
    transform: scale(1.05);
  }

  button:active {
    transform: scale(1);
  }

  @keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}
.pulse-animation{
  animation: pulse 1s ease-in-out infinite;
}

  .max-clear {
    font-weight: 300;
    text-shadow: 0 0 transparent;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    color: white;
    background: rgba(32, 29, 29, 0.65);
    border-radius: 10px;
    height: 40px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 90px;
    border-radius: 50px;
    transform: none;
    margin: 0px 14px;

    @media (max-width: 576px) {
      margin: 0px 6px;
      font-size: 11px;
    }
  }

  #chip10 {
    background: url("../assets/imgs/chips/Casino_Chip_White.svg") no-repeat;
  }

  #chip50 {
    background: url("../assets/imgs/chips/Casino_Chip_Red.svg") no-repeat;
    // margin-bottom: 20px;
  }

  #chip100 {
    background: url("../assets/imgs/chips/Casino_Chip_Blue.svg") no-repeat;
    // margin-bottom: 30px;
  }

  #chip500 {
    background: url("../assets/imgs/chips/Casino_Chip_Green.svg") no-repeat;
    // margin-bottom: 40px;
  }

  #chip1k {
    background: url("../assets/imgs/chips/Casino_Chip_Gray.svg") no-repeat;
    // margin-bottom: 50px;
  }

  #chip5k {
    background: url("../assets/imgs/chips/Casino_Chip_Orange.svg") no-repeat;
    // margin-bottom: 40px;
  }

  #chip10k {
    background: url("../assets/imgs/chips/Casino_Chip_Purple.svg") no-repeat;
    // margin-bottom: 30px;
  }

  #chip50k {
    background: url("../assets/imgs/chips/Casino_Chip_Brown.svg") no-repeat;
    // margin-bottom: 20px;
  }

  #chip100k {
    background: url("../assets/imgs/chips/Casino_Chip_Black.svg") no-repeat;
  }
}

.user-action-container .user-action {
  border: 2px solid transparent !important;
  background-color: transparent !important;
}

.user-action-container {
  position: absolute;
  width: 400px;
  height: 200px;
  top: 30%;
  // margin-top: -230px;
  left: 50%;
  margin-left: -200px;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  text-shadow: 0 0 10px black;
  z-index: 29;
  color: white;
  font-family: "Cinzel", serif;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ and Edge */
  user-select: none;
  /* Standard syntax */

  .user-action-text {
    pointer-events: none;
  }

  #your-turn-label {
    position: absolute;
    font-size: 24px;
    bottom: 179px;
    pointer-events: none;
    z-index: 999;
    // text-shadow: 0 0 50px black
  }

  .user-action-box {
    // width: 150px;
    text-align: center;
  }

  .user-action {
    position: relative;
    font-size: 58px;
    background-color: rgba(0, 0, 0, 0.5);
    width: 90px;
    height: 90px;
    outline: none;
    border: none;
    border-radius: 100%;
    border: 2px solid black;
    // box-shadow: 0 0 100px black;
    cursor: pointer;

    @media (max-width: 1000px) {
      width: 60px;
      height: 60px;
    }
    @media (max-width: 500px) {
      width: 45px;
      height: 45px;
    }
  }

  .user-action:hover {
    transform: scale(1.028);
    background-color: rgba(50, 50, 50, 0.5);
  }

  .user-action:active {
    transform: scale(1);
  }

  #stand {
    color: red;
    // margin-top: 40px;
  }

  #hit {
    color: green;
  }

  #doubleDown {
    position: relative;
    color: goldenrod;
    margin-top: 40px;

    span {
      position: absolute;
      left: 28px;
      top: 39px;
      font-size: 27px;
      color: rgb(78, 60, 13);
    }
  }

  // .user-action-text {

  // }
  @media (max-width: 575px) {
    height: 250px;
    top: 20%;
  }
}

.placebet {
  color: "green";
}
.ready {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  left: 50%;
  margin-left: -50%;
  top: 50%;
  margin-top: -50%;
  border-radius: 100%;
  font-weight: 700;
  font-size: 14px;
  font-family: "Cinzel", serif;
  outline: none;
  border: none;
  display: block !important;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-size: contain;
  background: linear-gradient(
      112.22deg,
      #ea9f28 15.42%,
      #cd851d 36.35%,
      #d29e2e 57.05%,
      #b37413 75.39%,
      #f1a72c 117.59%
    ),
    linear-gradient(0deg, #311902, #311902);
  opacity: 0.2;
  @media (max-width: 1000px) {
    font-size: 8px;
  }
}

@keyframes placeBetsColor {
  0% {
    background-color: rgba(255, 217, 0, 0.8);
  }

  50% {
    background-color: rgba(255, 217, 0, 0.5);
  }
}

.ready:hover {
  opacity: 0.5;
}

.ready:active {
  transform: scale(0.95);
}

// Dealers flipped card

.flip-card {
  background-color: transparent;
  width: 90px;
  height: 135px;
  perspective: 1000px;
  transition: 500ms;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

// .flip-card:hover .flip-card-inner {
//   transform: rotateY(-180deg);
// }

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

// MEDIA QUERIES

@media all and (max-width: 1300px) {
  #balance-bet-box {
    width: 100px;
    display: flex;
    flex-direction: column;

    .balance-bet {
      // height: calc(58px - 10px);
      // width: calc(90px - 10px);
      margin-top: 8px;
    }
  }
}

@media all and (max-width: 947px) {
  #balance-bet-box {
    bottom: 102px;
  }
}
@media all and (max-width: 947px) and (max-height: 500px) {
  #balance-bet-box {
    bottom: -2px;
  }
}

@media all and (max-width: 649px) {
  #balance-bet-box {
    flex-direction: row !important;
    width: 100% !important;
    padding-bottom: 20px;
  }
}

@media all and (max-width: 1200px) {
  .players {
    width: 90px;
    height: 90px;

    .empty-slot {
      font-size: 42px;
    }
  }

  .players-timer {
    width: 90px;
    height: 90px;
    .circle-animation {
      stroke: lime;
      stroke-dasharray: 301;
      stroke-dashoffset: 301;
      animation: dash 35s linear forwards;
    }
  }

  #bets-container {
    button {
      width: 35px;
      height: 35px;
    }
  }

  #balance-bet-box {
    width: 100px;
    display: flex;
    flex-direction: column;

    .balance-bet {
      // height: calc(58px - 10px);
      // width: calc(90px - 10px);
      margin-top: 8px;
    }
  }

  #bg-color-box {
    height: 260px;
    width: 42px;
    flex-direction: column;
    right: 20px;
    top: 60px;
  }

  #invite-link-box {
    width: 230px;

    #invite-label {
      padding: 0px 4px;
      font-size: 15px;
    }
  }
}
@media all and (max-width: 1000px) {
  #main-box #avatar-box .slideAvatars img {
    max-width: auto !important;
  }
  .avtarprofile {
    width: 30px !important;
    height: 30px !important;
    max-width: 40px !important;
  }
  #balance-bet-box .balance-bet {
    /* margin-top: 8px; */
    width: auto;
    font-size: 12px !important;
    height: 31px;
    position: absolute;
    left: 17px;
    bottom: 55px;
    min-width: 112px !important;
  }
  .players {
    width: 60px;
    height: 60px;
    margin-top: 73px;
    .empty-slot {
      font-size: 42px;
    }
    .player-name{
      text-align: center;
        left: -20px;
        top: 62px;
        width: 100px;
    }
  }
  :root {
    --info-rules-width: -392px;
  }

  #main-menu {
    #blackjack-title {
      font-size: 48px;
      margin-top: 4px;
      max-width: 70%;
      width: 247px;
      display: none;
    }
  }

  #info-rules {
    width: 400px;
  }

  #players-container {
    width: 90%;
    margin-left: -45%;
  }

  #players-timer-container {
    width: 86%;
    // width: 90%;
    margin-left: -44%;
  }

  #volume-button {
    right: 140px;
  }

  #bets-container {
    button {
      width: 50px;
      height: 50px;
    }
  }
}
@media all and (max-width: 992px) {
  #bets-container button {
    margin-top: 78px !important;
  }
  .progress-container.progress-container {
    // margin-top: -4px;
    height: 5px;
  }
  #game-room {
    // margin-top: 20px;
    height: calc(100dvh - 39px);
  }
  .player-result {
    width: 25px;
    height: 25px;
    font-size: 7px;
    top: -30px;
    left: 40%;
    padding: 2px;
  }
  #header-left {
    #room-name {
      display: none !important;
    }
    #leave-button {
      font-size: 12px !important;
      height: 31px;
      position: absolute;
      right: 17px;
      bottom: 45px;
    }
  }

  .card2 {
    top: -62px;
    margin-left: 13px;
  }

  .player-sum {
    left: 82%;
    padding: 4px 6px;
    top: -12px;
    font-size: 12px !important;
    font-weight: bold;
  }

  .players:nth-of-type(1),
  .players:nth-of-type(5) {
    margin-bottom: 224px;
  }
  #players-container {
    width: 82%;
    margin-left: -41%;
    bottom: 0px;
  }
  .players:nth-of-type(3) {
    margin-bottom: 224px;
  }
  #your-turn-label {
    font-size: 11px !important;
    font-weight: bold;
    bottom: 197px !important;
  }
  #main-box {
    width: 227px;
    margin: 36px auto 0 auto;
  }
  #main-box div {
    margin: 10px 0 0 !important;
  }
  #main-box div h3 {
    font-size: 13px;
    font-weight: 300;
  }

  #main-box .play-btns {
    margin-top: 0;
    margin-bottom: 14px !important;
    height: 39px !important;
    font-size: 13px;
  }

  .joinBox {
    margin: 13px 0 15px !important;
  }
  .joinroomtext {
    margin: 10px 0 !important;
  }
  #main-box #avatar-box .slideAvatars {
    width: 41px;
    height: 41px;
  }
  #blcktext {
    display: block;
    text-align: left;
    position: absolute;
    top: 11px;
    left: 30px;
  }
  #card-selection {
    margin: 52px 0 0 !important;
    gap: 11px !important;
  }

  .card p {
    margin-left: 14px;
    font-size: 12px !important;
  }
  .card img {
    width: 40px !important;
    height: 40px !important;
    max-width: 40px !important;
  }
  .card {
    width: 169px !important;
    padding: 10px !important;
  }
  .card img {
    width: 40px;
    height: 40px;
  }
  // .players {
  //   width: 40px;
  //   height: 40px;
  // }
  .players-timer {
    width: 60px;
    height: 60px;
  }
  .user-action-container .user-action {
    width: 50px;
    height: 50px;
  }

  .user-action-container .user-action-text {
    pointer-events: none;
    font-size: 10px;
  }
  #table-graphics {
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
    background-size: contain;
    transform: none;
    top: 13px;
  }
  .player-name {
    font-size: 9px;
    left: -94%;
    top: 46px;
    width: 100px;
  }
  .show-message-pop {
    min-width: 300px;
    max-width: 300px;
    margin-top: -125px;
    h4 {
      font-size: 11px;
      font-weight: 400;
      margin-top: -35px;
    }
  }
  #game-room {
    // height: calc(100vh - 58px);
    // overflow: hidden;
  }
  .balance-bet {
    white-space: nowrap;
    font-size: 13px;
    height: 29px;
  }
  #balance-bet-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    // bottom: -2px;
    bottom: -10px;
    justify-content: center;
  }
  #leave-table {
    bottom: 42px;
    display: none;
  }
  #bets-container {
    // padding-bottom: 0px;
    button {
      position: relative;
      width: 30px;
      height: 30px;
      font-weight: 500;
      font-size: 10px;
      margin-top: 40px;
    }
  }
  .players-timer {
    width: 60px;
    height: 60px;
    .circle-animation {
      stroke: lime;
      stroke-dasharray: 301;
      stroke-dashoffset: 301;
      animation: dash 53s linear forwards;
    }
  }
  #invite-link-box {
    // width: 132px;
    #invite-label {
      font-size: 14px;
    }
  }
  .bg_image{
    top: -25px;
  }
}
.bg-div{
  display: none;
}
@media all and (max-width: 576px) {
  #deal-start-label{
    margin-top: 81px;
  }
  // .bg_image{
  //   top:-60px;
  //  }
  .bg_image{
    width: 160%;
    // left: -30%;
    top: 180px;
  }
  .bg-div{
    display: block;
    background: #141627;
    height: 220px;
    .logo_image{
      width: 100px;
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
  }
  #players-container {
    bottom: 40px;
  }
  #pot-amount{
    top: 126px !important;
        margin-left: 0;
        left: 50%;
        transform: translateX(-50%);
  }
  #balance-bet-box{
    bottom: 0;
  }
  .players {
    width: 40px;
    height: 40px;
    .player-name{
      text-align: center;
        left: -30px;
        top: 42px;
        width: 100px;
    }
  }

  .players-timer {
    width: 60px;
    height: 60px;
    .circle-animation {
      stroke: lime;
      stroke-dasharray: 301;
      stroke-dashoffset: 301;
      animation: dash 53s linear forwards;
    }
  }

  #table-graphics {
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
    background-size: contain;
    transform: none;
    top: 0;
  }

  #bets-container .max-clear {
    margin: 0px 6px;
    font-size: 11px;
    width: 53px;
    height: 29px;
  }

  #bets-container button {
    width: 25px;
    height: 25px;
    font-size: 7px !important;
  }

  :root {
    --info-rules-width: -292px;
  }

  #main-box {
    width: 90%;

    .play-btns {
      width: 80%;
    }

    input {
      width: 60%;
    }
  }

  #about-box {
    width: 85%;
  }

  #main-menu {
    #blackjack-title {
      font-size: 42px;
      margin-top: 60px;
    }
  }

  #info-rules {
    width: 300px;

    #how-to-play {
      font-size: 10px;
      padding: 12px 6px;
      left: -73px;
    }
  }

  .players {
    // width: 50px;
    // height: 50px;

    .empty-slot {
      font-size: 22px;
    }
  }

  #bg-color-box {
    height: 180px;
    top: 38px;
    right: 10px;

    .bg-colors {
      width: 32px;
      height: 32px;
    }
  }

  #volume-button {
    top: 34px;
    left: 10px;
    width: 32px;
    height: 32px;
  }

  // #leave-button {
  //   padding: 0;
  // }

  #bets-container {
    height: 220px;

    // button {
    //   width: 30px;
    //   height: 30px;
    // }
  }
  #header-left #leave-button{
    bottom: 57px;
  }
  
}

@media all and (max-width: 400px) {
  #bets-container {
    // button {
    //   width: 54px;
    //   height: 54px;
    // }
    // .players:nth-of-type(4) {
    //   margin-bottom: 107px;
    //   margin-left: -21px;
    // }

    .players:nth-of-type(1),
    .players:nth-of-type(5) {
      margin-bottom: 250px;
    }

    .players:nth-of-type(2),
    .players:nth-of-type(4) {
      margin-bottom: 65px;
    }

    .players:nth-of-type(3) {
      margin-bottom: 225px;
    }

    .players-timer:nth-of-type(1),
    .players-timer:nth-of-type(5) {
      margin-bottom: 261px;
    }

    .players-timer:nth-of-type(2),
    .players-timer:nth-of-type(4) {
      margin-bottom: 175px;
    }

    .players-timer:nth-of-type(3) {
      margin-bottom: 225px;
    }
  }

  #invite-link-box {
    // width: 132px;
    #invite-label {
      font-size: 14px;
    }
  }

  #leave-table {
    bottom: -23px;
  }
}

@media all and (max-width: 386px) {
  #about-box {
    padding: 6px;

    ul {
      padding: 12px 0px 12px 12px;
    }
  }

  #main-menu {
    #blackjack-title {
      font-size: 36px;
      margin-top: 70px;
    }
  }

  #leave-button {
    font-size: 10px;
  }
}

.modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(10, 8, 7, 1);
  background-clip: padding-box;
  padding: 34px;
  max-width: 503px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  opacity: 1;
  transition: 300ms;
  z-index: 300;
  box-shadow: 0px 0px 50px -20px rgba(0, 0, 0, 0.75);
  color: #fff;
  border: 2px solid #ea9f28;
  border-radius: 10px;

  h2 {
    font-size: 22px;
    font-weight: 300;
  }

  p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
    font-weight: 200;
    margin: 20px 0px;
  }

  .pay-btns {
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: auto;
    width: auto;
    padding: 13px 42px;
    border-radius: 7.125rem;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background: linear-gradient(164deg, #ffca64 0%, #ca772a 100%);
    font-size: 16px;
    justify-content: center;
    margin-top: 44px;
    cursor: pointer;

    span i {
      display: flex;
      align-items: center;
      justify-content: center;
      // position: absolute;
      font-size: 20px;
      // border: 1px solid black;
      width: 38px;
    }
  }
}

// .modal-content {
//   background-color: #fefefe;
//   margin: 15% auto;
//   padding: 20px;
//   border: 1px solid #888;
//   width: 80%;
// }

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.fee-amount {
  font-size: 22px;
  color: rgba(255, 255, 255, 1);
  font-weight: 300;
}

.usdt-amount {
  font-size: 30px;
  color: rgba(217, 124, 39, 1);
  font-weight: 400;
}

// .close:hover,
// .close:focus {
//   color: black;
//   text-decoration: none;
//   cursor: pointer;
// }

#card-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 40px;
}

#card-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 40px;
}

.card img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.card p {
  margin-left: 15px;
  font-size: 16px;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 419px) {
  .card {
    width: 100%;
    max-width: 338px;
  }
}

/// exit room

#exitRoomDiv {
  position: absolute;
  left: 50%;
  top: 50%;
  width: calc(100% - 64px);
  max-width: 417px;
  background: #000;
  z-index: 100;
  transform: translate(-50%, -50%);
  padding: 27px;
  border: 1px solid #2f3458;
  background: #1d1f32;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25) inset;
  border-radius: 10px;
  @media all and (max-width: 992px) {
    top: 52%;
    width: calc(100% - 64px);
    max-width: 317px;
  }
  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  button {
    font-weight: 500;
    cursor: pointer;
    border-radius: 50px;
    border: none;
    padding: 13px 35px;
    font-size: 14px;
  }
  .yesbutton {
    color: #fff;
    background: linear-gradient(105.04deg, #ffca64 -4.56%, #ca772a 93.4%);
  }
  .noButton {
    color: #fff;
    background-color: #ffffff05;
    margin-left: 13px;
  }
  h2 {
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    font-family: "Readex Pro", sans-serif !important;
  }
}
// .players-circle-animation1 {
//   // border: 5px solid green;
//   &::after {
//     content: " ";
//     position: absolute;
//     left: 0;
//     top: 0;
//     border: 2px solid #d50000;
//     width: 100%;
//     height: 100%;
//     border-radius: 100px;
//     border-radius: 50%; /* Make it round */
//     animation: rotate360 35s linear infinite; /* Apply the animation */
//   }
// }
// @keyframes rotate360 {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
// .players-circle-animation1 {
//   width: 100%;
//   height: 100%;
//   border-radius: 50%;
//   border: 10px solid #f0f0f0;
//   position: absolute;
//   top: 0;
//   left: 0;
//   animation: progressAnimation 30s linear forwards;
// }
// .players-circle-animation1::after {
//   content: "";
//   display: block;
//   width: 50%;
//   height: 100%;
//   border-radius: 50%;
//   background-color: #4caf50;
// }

// #players-circle-animation,
// #players-container .players-circle-animation {
//   display: none;
// }
// @keyframes progressAnimation {
//   100% {
//     transform: rotate(360deg);
//   }
// }

@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-2px);
  }
  50% {
    transform: translateX(2px);
  }
  75% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(2px);
  }
}

// .vibrating-coin {
//   animation: vibrate 0.9s infinite;
// }

// no internet
// .no-internet {
//   position: fixed;
//   top: 20%;
//   left: 0;
//   // width: 100vw;
//   // height: 100vh;
//   // background: #1d1f32;
//   justify-content: center;
//   align-items: center;
//   display: flex;
//   z-index: 100;
//   background-color: orange;
//   border-radius: 10px;
//   padding: 10px;
//   animation: blink-animation 1s infinite;

//   div {
//     padding: 20px;
//     font-size: 12px;
//   }
// }
// .no-internet div {
//   padding: 0px !important;
//   font-size: 12px;
// }
// .no-refresh {
//   top: 20%;
//   left: 50%;
//   // width: 100vw;
//   // height: 100px;
//   transform: translate(-50%, -50%);
//   div {
//     justify-content: center;
//     align-items: center;
//     display: flex;
//     height: 100%;
//   }

//   @media (max-width: 991px) {
//     top: 40%;
//   }
// }

// no internet
.no-internet {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background: #1d1f32;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 100;

  div {
    padding: 10px;
    font-size: 12px;
  }
}
.no-refresh {
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 60px;
  transform: translate(-50%, -50%);
  div {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
  }
}
.progress-container {
  width: 100%;
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
  bottom: -2px;
  position: absolute;
  z-index: 1;
}

.progress-bar {
  height: 100%;
  width: 0%;
  background-color: #d51717;
  border-radius: 3px;
  animation: progressAnimation 30s linear forwards;
}

@keyframes progressAnimation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.rotate {
  -webkit-animation: rotate 5s linear infinite normal;
  animation: rotate 5s linear infinite normal;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.betButtons{
  span{
    position: absolute;
    left: -18px;
    bottom: -16px;
    width: 100px;
    color: #fff;
    text-shadow: none;
    font-family: "Readex Pro", sans-serif;
    font-weight: 300;
    font-size: 13px;
  }
  @media (max-width: 1000px) {
    span{
      left: -8px;
      bottom: -11px;
      width: 48px;
      font-size: 8px;
    }
  }
  @media (max-width: 576px) {
    span{
      left: -10px;
    }
  }
}
#loading-screen1{
  position: fixed;
    width: 100dvw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background: rgba(0,0,0,0.8);
    div{
      margin-top: 80px;
      padding: 0 40px;
      text-align: center;
    }
}

.win-confetties {
  left: -100% !important;
}

.testCss .MuiDialog-container {
  width: 50% !important;
  margin: auto !important;
}

.testCss .MuiPaper-root {
  top: 10%;
}

@media screen and (max-width: 1440px) {
  .spin-modal {
    min-width: 20rem !important;
  }
  .vel-modal {
    min-width: 20rem !important;
  }
}

@media screen and (max-width: 800px) {
  .testCss .MuiDialog-container {
    width: 100% !important;
  }

  .auth-modal.right-panel-active .sign-up-container {
    width: 100% !important;
    left: -100% !important;
  }

  .spin-modal {
    /* width: 95vw !important; */
    min-width: 25rem !important;
    max-width: 95vw !important;
  }
  .val-modal {
    /* width: 95vw !important; */
    min-width: 25rem !important;
    max-width: 75vw !important;
  }
}

@media screen and (max-width: 425px) {
  .spin-modal,
  .vel-modal,
  .spin-result-modal {
    /* min-width: 22rem  */
    min-width: 18rem !important;
    /* scale: .9; */
  }
  .spin-result-modal {
    height: 60dvh !important;
    width: 90dvw !important;
  }
}

@media screen and (max-width: 320px) {
  .spin-modal,
  .vel-modal,
  .spin-result-modal {
    min-width: 18rem !important;
    /* scale: .87; */
  }
}


.testCss .MuiDialog-scrollPaper {
  display: block !important;
}

.testCss #container {
  width: 100%;
}

.auth-modal {
  height: 70dvh;
  min-width: 85%;
  width: 50dvw;
  max-width: 50% !important;
}

.check-term-condition {
   /* height: 30dvh; */
   min-width: 85%;
    width: 50dvw;
   /* max-width: 50% !important; */
}

.google-sign-btn {
   background: #fff;
   border-radius: 5px;
   padding: .8rem;
}

/* .slick-slide {
  width: 1000px !important;
} */

.slick-dots {
  display: none !important;
}
@media screen and (max-width: 1024px) {
  .auth-modal,.check-term-condition {
    min-width: 80%;
    width: 85dvw;
  }
}

@media screen and (max-width: 768px) {
  .auth-modal,.check-term-condition {
    min-width: 100%;
    width: 95dvw;
  }
}
#launcher{z-index: 999 !important;}
.vel-modal {
  height: 631px;
  min-width: 20rem;
  width: 22rem;
  max-width: 30rem;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.spin-modal {
  height: 75dvh;
  min-width: 30rem;
  width: 30rem;
  max-width: 30rem;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.spin-modal1 {
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.spin-result-modal {
  background-image: url('../assets/imgs/games/spinRewardBg.png');
  /* background-image: url('/images/games/spinRewardBg.png'); */
  /* background: radial-gradient(circle, rgba(17,5,56,1) 20%, rgba(107,11,162,1) 80%); */
  background-size: 55dvh;
  background-position: center;
  object-fit: contain;
  height: 45dvh;
  min-width: 100%;
  width: 25rem;
  overflow: hidden;
  min-height: 50dvh !important;
}

.font-grandstander {
  font-family: "Grandstander", cursive;
  background-image: linear-gradient(to bottom, #FFC236,#FFE681);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}

.wheel-container {
  width: 380px !important;
  height: 380px !important;
  min-width: 380px !important;
  min-height: 380px !important;
  background-image: url("../assets/imgs/games/spinBorder.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  scale: 0.8;
  transform: translateY(100px);
  /* filter: drop-shadow(0px 0px 20px black); */
}

@media screen and (max-height: 820px) {
  .wheel-container {
    scale: 0.7;
    transform: translateY(150px);
  }
}

.spin-center {
  border-radius: 50%;
  /* position: relative; */
  filter: drop-shadow(0px 0px 20px black);
}

.spin-footer-button {
  /* background: radial-gradient(circle, rgba(52,5,115,0.5046393557422969) 8%, rgba(90,12,172,1) 72%);
  */
  /* background: radial-gradient(circle, rgba(53, 5, 115, 0.959) 64%, rgba(90,12,172,1) 100%); */
  background: radial-gradient(
    circle,
    rgba(64, 5, 115, 0.8603816526610644) 0%,
    rgba(90, 12, 172, 1) 100%
  );
  outline: 2px solid rgb(254, 209, 29);
}

.spin-ring {
  width: 330px !important;
  height: 330px !important;
  min-width: 330px;
  max-width: 330px;
  position: absolute;
  z-index: 8;
  top: 3.6%;
  left: 6%;
  transform: rotate(36deg);
  filter: drop-shadow(0px 0px 10px black);
}

#spin-wheel {
  width: 300px !important;
  height: 300px !important;
  transform: translateY(-11px) rotate(0deg);
  transition: transform 1s;
}

.wheel-spin {
  animation: wheelRotate 0.3s linear infinite forwards;
  /* animation-timeline: 1; */
}

.vectorImage {
  animation: vectorRotate 5s linear infinite;
  transform: scale(1.7) rotate(0deg);
}

@media screen and (max-height: 700px) {
  .remainSpinText {
     transform: translateY(20px) !important;
  }
  .claim-button {
     margin-top: 15px !important;
  }
  .wheel-container {
     transform: translateY(100px) !important;
  }
}

@media screen and (max-height: 620px) {
  .remainSpinText {
     transform: translateY(-26px) !important;
  }
  .claim-button {
     margin-top: -26px !important;
  }
  .wheel-container {
    transform: translateY(59px) !important;
 }
}



@keyframes vectorRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes wheelRotate {
  100% {
    transform: translateY(-11px) rotate(-360deg);
  }
}

/* .slick-slide {
 width: 1000px !important;
} */

.result-amount {
  background: -webkit-linear-gradient(#ffc236, #ffe681);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.claim-button {
  background-image: url("../assets/imgs/games/claimRewardButton.png");
  /* width: 50%; */
  /* height: 100px; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  outline: none;
}

.purchase-button {
  width: 220px;
  display: grid;
  place-items: center;
  padding: 0.5rem;
  border-radius: 50px;
  background: rgb(169, 22, 255);
  border: 2px solid rgb(255, 166, 0);
  cursor: pointer;
}

.coin-group {
  animation: coin-scale 0.6s ease-in 1 forwards;
}

.slick-dots {
  display: none !important;
}

.light-circle {
  width: 12.5px;
  height: 12.5px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  box-shadow: 0 0 10px 2px rgb(255, 255, 255);
  z-index: 10;
}

.light-circle-1 {
  top: 2px;
  left: 138px;
}

.light-circle-2 {
  top: 2px;
  left: 229px;
}

.light-circle-3 {
  top: 48px;
  left: 308px;
}

.light-circle-4 {
  top: 33%;
  right: 4%;
}
.light-circle-5 {
  top: 57%;
  right: 4%;
}

.light-circle-6 {
  top: 78%;
  right: 16%;
}

.light-circle-7 {
  top: 90%;
  right: 37%;
}

.light-circle-8 {
  top: 90%;
  right: 60%;
}

.light-circle-9 {
  top: 78%;
  right: 81%;
}
.light-circle-10 {
  top: 57%;
  right: 93%;
}
.light-circle-11 {
  top: 33%;
  right: 93%;
}
.light-circle-12 {
  top: 13%;
  right: 81%;
}

@keyframes coin-scale {
  from {
    transform: scale(0.2);
  }
  to {
    transform: scale(1);
  }
}

.spinCloseButton {
  position: absolute;
  right: 2%;
  top: 2%;
  cursor: pointer;
}


@media screen and (max-width: 600px) and (max-height: 700px) {
  .spin-result-modal {
    height: 65dvh;
    width: 85dvw;
  }
}

@media screen and (max-height: 700px) {
  .spin-result-modal {
    min-height: 65dvh !important;
  }
}