body {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  background: #141627;
  color: #ffffff;
  // font-family: "K2D", sans-serif;
  font-family: "Readex Pro", sans-serif !important;
}

.dFlexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justifyBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textFieldSection {
  width: 100%;
}
.width100 {
  width: 100%;
}
.royalrightImg {
  width: auto !important;
  max-width: 97px;
  margin-top: 34px;
  margin-right: -3px;
}

.numberText1 {
  left: -70px !important;
}

.numberText {
  position: absolute;
  left: -46px;
  font-size: 101px !important;
  color: #ca772a;
  top: 54px;
  z-index: 9;
}

.layoubackgroundImg {
  position: fixed;
  width: auto;
  max-width: 100%;
  opacity: 0.5;
  // z-index: 1;
}

.sideMenuBox {
  // height: calc(100vh - 450px);
  overflow: auto;
}
.sideMenuBox1 {
  height: 100%;
  overflow: auto;
}
.buttonLeaf1 {
  font-size: 12px;
  span {
    color: rgba(255, 255, 255, 0.7);
  }
}
.sideMenuBox::-webkit-scrollbar {
  display: none;
}

.logoDrawerBox {
  padding-top: 20px;
  /* top: 20px; */
  width: auto;
  cursor: pointer;
  max-width: 119px;
  padding-bottom: 22px;
}

.paperBox {
  background-color: rgba(255, 255, 255, 0.025);
  border-radius: 15px;
  padding: 20px;
}

.slickbottomslider {
  width: 100%;
  position: relative;

  .slick-initialized .slick-slide.slick-current {
    .active {
      transform: scale(1.15);
      transition: all 0.4s ease-in;
    }
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 1 !important;
    color: #fff;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    margin-top: -17px !important;
  }

  .slick-track {
    padding: 60px 0;
  }

  .slick-prev:before {
    content: "‹";
  }

  .slick-next:before {
    content: "›";
  }

  .slick-list {
    padding-left: 0px !important; // half partial
  }

  .slick-prev {
    left: auto !important;
    // bottom: -20px !important;
    top: -14px !important;
    right: 60px !important;
    color: #fff !important;
    z-index: 999 !important;
    background-color: #1c1c1b !important;
    width: 40px;
    height: 40px;
    border-radius: 50px;

    @media (max-width: 767px) {
      top: 21px !important;
    }
  }

  .slick-next {
    top: -14px !important;
    // bottom: -20px !important;
    left: auto !important;
    right: 5px !important;
    color: #fff !important;
    background-color: #161616 !important;
    z-index: 999 !important;
    width: 40px;
    height: 40px;
    border-radius: 50px;

    @media (max-width: 767px) {
      top: 21px !important;
    }
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 40px;
    line-height: 1;
    opacity: 0.75;
    color: #f5c843;
    margin-top: -17px;
  }
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 18px !important;
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: rgba(255, 212, 129, 1) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 1px solid rgba(255, 212, 129, 1);
  border-radius: 50px;
  width: 21px !important;
  height: 21px !important;
  line-height: 23px !important;
}

.slick-dots {
  bottom: -42px !important;
  z-index: 999;

  @media (max-width: 1556px) {
    bottom: -42px !important;
  }
}

.slickbottomslider {
  .slick-track {
    padding: 40px 0 23px;
  }
}

.yellowShade {
  position: absolute;
  width: 1000px;
  height: 829px;
  left: 30%;
  top: 50%;
  transform: translateX(-50%);
  background: radial-gradient(
    29.3% 29.3% at 55.72% 49.12%,
    #ffca64 0%,
    rgba(245, 200, 67, 0) 100%
  );
  opacity: 0.45;
  filter: blur(190px);
  max-width: 100%;
}

.tableShadow {
  position: absolute;
  width: 1000px;
  height: 829px;
  left: 50%;
  top: -13%;
  transform: translateX(-50%);
  background: radial-gradient(
    29.3% 29.3% at 55.72% 49.12%,
    #ffca64 0%,
    rgba(245, 200, 67, 0) 100%
  );
  opacity: 0.45;
  filter: blur(301px);
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  // background: linear-gradient(
  //   92.79deg,
  //   #ffb000 -32.2%,
  //   #ffb000 5.51%,
  //   #ca772a 54.01%,
  //   #ca772a 110.93%
  // );

  background: #292e52;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    92.79deg,
    #ffb000 -32.2%,
    #ffb000 5.51%,
    #ca772a 54.01%,
    #ca772a 110.93%
  );
}

::-webkit-scrollbar-track {
  background: #141627;
  border-radius: 5px;
  border: 1px solid #141627;
}

::-webkit-scrollbar-corner {
  background: #141627;
}

////////h2h//////

body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  // font-family: 'Saira', sans-serif;
  // background: #000;
}

.royalText {
  font-size: 35px !important;
  font-weight: 600;
  font-family: "K2D", sans-serif !important;
  line-height: 45px !important;
  text-align: left;
  text-transform: uppercase;
}

.displayWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.displayColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.displayStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.displayEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectGridBox {
  width: 20%;
}

.logoImg {
  width: 70px;
}

.centerLogoBox {
  position: absolute;
  margin: 0 auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 500px) {
    display: none;
  }
}

.bgx1 {
  -webkit-animation: rotating 26s linear infinite;
  animation: rotating 26s linear infinite;
}

.bgx2 {
  -webkit-animation: rotating2 6s linear infinite;
  animation: rotating2 6s linear infinite;
}

.crypto {
  -webkit-animation: rotating3 3s linear infinite;
  animation: rotating3 3s linear infinite;
}

@-webkit-keyframes rotating3 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

.goldshadow {
  width: 800px;
  top: 25%;
  opacity: 0.3;
  filter: blur(130px);
  height: 400px;
  border-radius: 1000px;
  left: 23%;
  z-index: -1;
  position: absolute;
  display: none;
  background-image: radial-gradient(
    22.67% 9.68% at 67.26% 8.27%,
    #ffca64 86%,
    #ffca64 41%
  );

  @media (max-width: 991px) {
    width: 216px;
    height: 216px;
  }

  @media (max-width: 401px) {
    width: 106px;
    height: 106px;
  }
}

@keyframes rotating3 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotating {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotating2 {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes rotating2 {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.sliderBox {
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background-color: #121212;
  border-top: 3px solid #e35f24;
  text-align: center;

  img {
    width: auto;
    max-width: 165px;
  }
}

.shadeBox {
  right: auto;
  left: 0;
  width: 450px;
  /* bottom: auto; */
  filter: blur(230px);
  height: 550px;
  opacity: 0.55;
  top: 0;
  z-index: -1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(
    36.67% 9.68% at 67.26% 8.27%,
    #ff6600 0%,
    #ff66004f 95.78%
  );
}

.orangeshadeBox {
  right: 0;
  left: 34%;
  width: 400px;
  /* bottom: auto; */
  filter: blur(147px);
  height: 400px;
  opacity: 0.55;
  top: 42%;
  z-index: -1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background: linear-gradient(135deg, #4d8bfb 0%, #242846 100%);
}

.TopbarButton {
  padding: 8px 24px;
  // margin-right: 10px !important;
}

.centerImg {
  position: absolute !important;
  left: 0 !important;
  /* background: gray; */
  width: 38px;
  margin-right: 41px;
}

.centerbannerimg {
  position: absolute;
  top: 80%;
  left: 70%;
  transform: translate(-50%, -50%);
  width: 100% !important;
  z-index: 1;
  opacity: 0.5;
  min-width: 800px;
  max-width: 60%;
}

.orangeshadeBoxWon {
  right: 0;
  left: 0;
  width: 300px;
  /* bottom: auto; */
  filter: blur(70px);
  height: 300px;
  /* opacity: 0.55; */
  top: 0;
  z-index: 1;
  position: absolute;
  transform: rotate(45deg);
  /* border-radius: 1000px; */
  background-image: radial-gradient(
    36.67% 9.68% at 67.26% 8.27%,
    rgb(247 164 5 / 0%) 0%,
    rgb(248 136 6 / 19%) 95.78%
  );
}

.dollerImg1 {
  position: absolute;
  right: 40px;
  width: auto !important;
  max-width: 150px !important;
  top: 42%;
}

.dollerImg2 {
  // position: absolute;
  // left: 40px;
  // width: auto !important;
  // max-width: 150px !important;
  // top: 42%;

  position: absolute;
  left: 45%;
  width: auto !important;
  max-width: 150px !important;
  top: -13%;
  transform: translateX(-50%);
}

.dollerImg3 {
  position: absolute;
  left: 45%;
  width: auto !important;
  max-width: 119px !important;
  bottom: -29px;
}

.ticketbannerImg {
  position: absolute;
  top: -122px;
  z-index: -1;
  width: auto !important;
  max-width: 100% !important;
  display: none;
}

.ticketAnimationBox {
  animation: 3s ease-in-out 0s infinite normal none running rotating4;
}

@keyframes rotating4 {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(6deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  backdrop-filter: blur(4px);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}

.animationText {
  color: #fff;
  animation: 2s anim-lineUp ease-out infinite;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0.5;
    transform: translateY(80%);
  }

  20% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.3;
    transform: translateY(0%);
  }

  50% {
    opacity: 0.2;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.hedaerTopbar {
  padding: 7px 0 0;
}

.containerBox {
  padding: 0px 80px !important;
}

.mainTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #5e4a2e;
  border-radius: 50px;
  width: fit-content;
  padding: 3px;
}

.tabActiveButtons {
  background: linear-gradient(164deg, #ffca64 0%, #ca772a 100%);

  border-radius: 50px;
  padding: 11px 35px;
  color: #fff !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  transition: all 500ms ease-in-out;
  font-family: "Readex Pro";

  @media (max-width: 768px) {
    padding: 11px 23px;
  }
}

.tabButtons {
  border-radius: 50px;
  padding: 11px 35px;
  white-space: pre;
  cursor: pointer;
  color: rgba(184, 184, 184, 1);
  font-family: "Readex Pro";

  @media (max-width: 768px) {
    padding: 11px 23px;
  }
}

.mainTab1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  overflow: auto;
}

.mainTab1::-webkit-scrollbar {
  display: none;
}

.outerScrollTab {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #ffffff11;
  border-radius: 50px;
  padding: 3px 20px;
}

.tabActiveButtons1 {
  background: linear-gradient(164deg, #ffca64 0%, #ca772a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 11px 20px;
  color: #e49f46 !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  font-family: "Readex Pro";

  @media (max-width: 768px) {
    padding: 8px 15px;
  }
}

.tabButtons1 {
  border-radius: 50px;
  padding: 11px 20px;
  white-space: pre;
  cursor: pointer;
  color: rgba(184, 184, 184, 1);
  font-family: "Readex Pro";

  @media (max-width: 768px) {
    padding: 8px 15px;
  }
}

.MuiPaginationItem-outlined {
  border: 1px solid #181719 !important;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: #d2883f !important;
  border: 1px solid #f5bd64 !important;
  color: #fff !important;
  border-radius: 10px;
}

.MuiPaginationItem-root {
  height: 40px;
  border-radius: 10px;
  min-width: 40px;
}
.tabDisplayBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.textTabBox {
  padding: 5px 15px;
  cursor: pointer;
  color: #ffca64;
  border-bottom: 2px solid #ffca64;
}

.textTabActiveBox {
  padding: 5px 15px;
  color: rgb(184, 184, 184);
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.dialogStyle {
  .MuiDialog-paperFullWidth {
    background: rgba(10, 8, 7, 1);
    box-shadow: 0 1px 0 0 #ffca64, 0 -1px 0 0 #f4a91b, 1px 0 0 0 #ca772a,
      -1px 0 0 0 #ca772a, 1px -1px 0 0 #f4a91b, -1px 1px 0 0 #ca772a,
      1px 1px 0 0 #f4a91b, -1px -1px 0 0 #ca772a;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.backButton {
  background: linear-gradient(105.04deg, #ffca64 -4.56%, #ca772a 93.4%);
  padding: 8px !important;
  margin-right: 8px !important;
}

.tooltipBox {
  font-size: 200px;
}

.displayAlign {
  display: flex;
  align-items: center;
}

.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 1%);
}

.leaderboardButton {
  background: var(--linear, linear-gradient(164deg, #ffca64 0%, #ca772a 100%));
  border-radius: 0px 0px 30px 30px;
  min-width: 105px;
  width: 245px;
  min-height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lederboardCenter {
  display: flex;
  justify-content: center;
  margin-top: -42px;
}

.closeButton {
  position: absolute !important;
  right: 11px !important;
  top: 2px !important;
}

.tickenumber {
  color: #fff;
  padding: 16px 12px;
  width: 43px !important;
  border: 1px solid #3f467d;
  position: relative;
  background: #222545;
  border-radius: 10px !important;
  @media (max-width: 600px) {
    padding: 13px 4px;
  }
}

.zoomed {
  transform: scale(1.3);
  /* Increase size by 20% */
}

.myCustomClass1::after {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.myCustomClass1:nth-bild(odd)::after {
  background: linear-gradient(
    90deg,
    #135a38 0%,
    rgba(13, 135, 204, 0.97) 43.68%,
    rgba(0, 0, 0, 0) 114.45%
  );
}

.myCustomClass::after {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.myCustomClass:nth-child(odd)::after {
  background: linear-gradient(
    90deg,
    #81155c 0%,
    rgba(57, 70, 187, 0.91) 42.53%,
    rgba(0, 0, 0, 0) 107.41%
  );
}

// .mainStepBox {
//   border: 7px solid #eab671;
//   padding: 30px 44px;
//   position: relative;
//   background: #000;
//   border-radius: 20px 0px 0px 20px;
//   ::after {
//     top: 14%;
//     right: -7px;
//     height: 59%;
//     content: url(images/afterboreder.png);
//     z-index: 9;
//     position: absolute;
//     background: black;
//   }
// }

.trade-full {
  height: 300px;

  @media (max-width: 600px) {
    height: 250px;
  }
}

.mainClx {
  color: rgba(255, 255, 255, 0.6);
}

.mainClx span {
  color: rgba(255, 255, 255, 0.6) !important;
}

.contactusText {
  cursor: pointer;
  font-size: 16px !important;
  text-decoration: underline;
  color: orange;
}

.socialButton {
  padding: 10px !important;
}

.royalText1 {
  position: absolute;
  right: -1px;
  transform: rotate(28deg);
  top: 16px;
  font-weight: bold;
  font-size: 12px;
  background: #ca772a;
  padding: 4px 0x;
  padding: 6px;
  /* border-radius: 10px; */
  /* margin: 10px; */
  z-index: 9;
}

.marquebackground {
  padding: 68px 0 40px;
  @media (max-width: 767px) {
    padding: 20px 0 40px;
  }
}

.marqueTextdescription {
  margin: 9px !important;
  font-size: 14px !important;
  background: #111111;
  padding: 17px 28px;
  border-radius: 20px;
  border: 1px solid rgba(237, 174, 80, 0.1803921569);
  border-radius: 100px;
  border: 1px solid #2f3458;
  color: rgba(255, 255, 255, 0.8) !important;
  background: #1d1f32;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25) inset;
}

.slickbottomsliderNew {
  .slick-dots li button {
    font-size: 0 !important;
    line-height: 0 !important;
    display: block !important;
    width: 20px !important;
    height: 20px !important;
    padding: 5px !important;
    cursor: pointer !important;
    color: transparent !important;
    border: 0 !important;
    outline: 0 !important;
    background: 0 0 !important;
  }

  .slick-dots li button {
    font-size: 0 !important;
    line-height: 0 !important;
    display: block !important;
    width: 20px !important;
    height: 20px !important;
    padding: 5px !important;
    cursor: pointer !important;
    color: transparent !important;
    border: 0 !important;
    outline: 0 !important;
    background: 0 0 !important;
  }

  .slick-dots li button:before {
    font-size: 6px !important;
    line-height: 20px !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 26px !important;
    height: 3px !important;
    border: none !important;
    content: "•" !important;
    text-align: center;
    opacity: 0.25;
    background-color: #f7941d !important;
    color: transparent !important;
  }

  .slick-dots li button:before,
  .slick-next:before,
  .slick-prev:before {
    font-family: slick;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.profileButton {
  border: 1px solid #fff !important;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.25) !important;
  background: transparent !important;
}

// Phone input style..

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #242846 !important;
  border-radius: 3px 0 0 0;
}

.react-tel-input .form-control {
  width: 100%;
  height: 42px;
  border-radius: 36px;
  background: #242846 !important;
  border: 1px solid #3f467d;
  color: #fff;
}

.react-tel-input .flag-dropdown {
  border-radius: 20px 0px 0px 20px !important;
  border: 1px solid #3f467d !important;
  background: #242846 !important;
}

.react-tel-input .selected-flag {
  border-radius: 20px 0px 0px 20px !important;
  background: #242846 !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 20px 0px 0px 20px !important;
  background: #242846 !important;
}
.lotteryquantityBox {
  .MuiOutlinedInput-root {
    border: 1px solid #3f467d;
    position: relative;
    background: #222545;
    border-radius: 10px !important;
  }

  .MuiOutlinedInput-input {
    padding: 15.5px 1px;
    text-align: center;
  }
  .MuiFormControl-root {
    width: 45px;
  }
}

.prizetext {
  min-width: 141px;
  text-transform: uppercase;
}
.MuiSelect-select:focus {
  border-radius: 100px !important;
}
.react-tel-input .country-list {
  background: #292d4f;
}

.react-tel-input .country-list .country.highlight {
  background: #424879;
}

.react-tel-input .country-list .country:hover {
  background: #424879;
}

.MuiAutocomplete-inputRoot {
  padding: 4px 9px !important;
}

// DatePicker...
.MuiPickersBasePicker-pickerView {
  padding: 10px !important;
}

.logoClientBox {
  width: auto;
  max-width: 100px;
  margin: 0 20px;
}

.featuresBox {
  width: 100%;
  display: -webkit-box;
  position: relative;
  overflow-x: scroll;
  margin-bottom: 50px;

  p {
    margin-top: 10px;
  }

  h5 {
    color: #fff;
  }
}

.featuresMainBox {
  padding: 50px 0 60px;
}

.featuresCradBox {
  background: #111111;
  border-radius: 20px;
  border: 1px solid #2f3458;
  color: rgba(255, 255, 255, 0.8) !important;
  background: #1d1f32;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25) inset;
  overflow: hidden;
  height: 100%;
  min-height: 229px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  p {
    margin-top: 10px;
  }

  h5 {
    color: #fff;
  }
}
.scrollBox {
  overflow-y: auto;
  max-height: 200px;
}
.MuiMenu-paper {
  max-height: calc(100% - 96px);
  background-color: #222545 !important;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #3f467d45;
}

.MuiTimelineConnector-root {
  width: 2px;
  height: 108px;
  flex-grow: 1;
  background-color: rgba(43, 48, 84, 0.5) !important;
  // background-image: url(./images/line.png);
}

.numberBox {
  width: 40px;
  height: 40px;
}

.MuiTimelineDot-defaultGrey {
  color: #fafafa;
  border-color: transparent !important;
  background-color: transparent !important;
}

.MuiTimelineDot-defaultPrimary {
  color: #fafafa;
  border-color: transparent !important;
  background-color: transparent !important;
}

.graphMenuClx {
  .apexcharts-menu.apexcharts-menu-open {
    background: #1c1f36 !important;
    border: 1px solid #f4a91b54 !important;
    border-radius: 10px !important;
  }

  .apexcharts-theme-light .apexcharts-menu-item:hover {
    background: #eeeeee5c !important;
    border-radius: 5px !important;
  }

  .apexcharts-menu-item.exportSVG {
    color: #ffffffe6 !important;
  }
}

.custom-facebook-button-class {
  /* Add your custom styles here */
  background-color: #3b5998;
  /* Facebook blue color */
  color: white;
  /* Add more styles as needed */
}
.MuiMenuItem-root {
  font-size: 12px;
}

.logotoside {
  margin-top: 52px;
}
.logobottomside {
  margin-top: 72px;
}
.horsetopBox {
  margin-top: 98px;
}
.horseBox {
  margin-top: 0px;
}
.jointTextBox {
  margin-top: 26px;
}
.shadeblue {
  position: absolute;
  inset: auto 10% 10% auto;
  transform: rotate(45deg);
  z-index: -1;
  width: 300px;
  height: 200px;
  border-radius: 1000px;
  background-image: radial-gradient(
    36.67% 9.68% at 67.26% 8.27%,
    rgb(42, 123, 135) 0%,
    rgb(39, 121, 124) 95.78%
  );
  opacity: 0.55;
  filter: blur(100px);
}

.MuiTableCell-root {
  font-size: 11px !important;
}
.orDivider {
  position: relative;
  p {
    position: relative;
    padding: 2px 10px;
    background: #292d4f;
    display: inline-block;
  }
  &::before {
    content: "";
    height: 1px;
    background-color: #444b84;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;
  }
}

.responsibilitiesBox {
  padding: 40px 0 40px;
}

.gameplayCard {
  padding: 5px 18px;
  font-size: 16px !important;
  background: #252947;
  font-weight: 500 !important;
  line-height: 33px;
  border-radius: 20px 20px 0px 0px;
  text-transform: uppercase;
}
.remaiderBox {
  padding: 15px;
}

.google-login-button {
  font-size: 16px; /* Adjust the font size as needed */
}

.MuiSvgIcon-fontSizeSmall {
  color: #fff !important;
}
.blink-soft {
  animation: blinker 1.5s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.coubtrymainBox {
  .MuiFormControl-fullWidth {
    width: 100%;
    border: 1px solid #3f467d !important;
    border-radius: 100px;
  }
}

.blink-soft {
  animation: blinker 1.5s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

blinking-text-container {
  position: relative;
}

.blinking-text {
  background-color: orange;
  border-radius: 10px;
  padding: 10px;
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
